.text-pink {
  color: #ec1d63;
}

.text-success {
  color: #06bd53 !important;
}

.mobile_header {
  justify-content: space-between;
}

.mobile_nav,
.message_link {
  background: #222731;
  padding: 4px 15px;
  border-radius: 30px;
  min-width: 48px;
  height: 32px;
  display: inline-block;
}

.dashboard_head h1 {
  font-size: 20px;
  color: #9a1032;
  display: inline-block;
  position: relative;
  padding: 0 36px;
}

.dashboard_head h1:after,
.dashboard_head h1:before {
  content: "";
  width: 26px;
  height: 1px;
  background: #969696;
  position: absolute;
  right: 0;
  top: 50%;
}

.dashboard_head h1:before {
  left: 0;
}

.ChatMobile form .form-control {
  background-color: #222731;
  border-radius: 18px;
  font-size: 14px;
  border: 0;
  padding-left: 40px;
}

.ChatMobile form span i {
  color: #06c153;
}

.ChatMobile form span {
  left: 15px;
  bottom: 7px;
}

.ChatMobile a {
  font-size: 14px;
  color: #06c153;
  font-weight: 500;
}

.ChatMobile .user img,
.ChatMobile .user i {
  width: 50px;
  height: 50px;
}

.ChatMobile .user i {
  background-color: #e81d63;
  border-radius: 100%;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ChatMobile .inbox_text {
  max-width: 250px;
}

.ChatMobile .inbox span i {
  width: 12px;
  height: 12px;
  color: #df1e4d;
}

.ChatMobile .inbox h3 {
  font-size: 16px;
  color: #efefef;
  font-weight: 400;
  margin-bottom: 5px;
}

.ChatMobile .inbox p {
  font-size: 14px;
  color: #b0b0b0;
  font-weight: 400;
  margin-bottom: 0;
}

.ChatMobile .inbox h2 {
  font-style: italic;
  font-size: 12px;
  color: #929292;
  margin: 10px 20px 0;
  font-weight: 400;
}

.ChatMobile .border-top {
  border-top: 1px solid #929292 !important;
}

.chat_gem {
  padding: 20px;
  border-radius: 100%;
  background-color: #121212;
  border: 2px solid #06bd53;
  position: fixed;
  bottom: 150px;
  right: 40px;
  box-shadow: 0 10px 11px rgb(8 139 33 / 70%);
}

.msgIncoming {
  background: #525665;
  color: #e1e1e1;
  font-size: 14px;
}

.msgOutgoing {
  background: #06bd53;
  float: right;
}

.msg {
  border-radius: 7px;
  padding: 10px 10px 24px;
  max-width: 220px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
  clear: both;
}

.chatdetail h6 {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #969696;
}

.chatScroll:after,
.chatdetail:after {
  clear: both;
  display: block;
  content: "";
}

.msg span {
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-weight: 300;
  font-size: 12px;
}

.send {
  clear: both;
  position: fixed;
  background-color: #000;
  /* bottom: 0; */
  left: 0;
  width: 100%;
  padding: 20px 28px;
}

.send textarea {
  background: #222731;
  color: #737373;
  font-weight: 300;
  font-size: 14px;
  border: 0;
  resize: none;
  height: 36px;
  border-radius: 35px;
  padding: 7px 20px 7px 40px;
}

.send img {
  position: absolute;
  top: 9px;
  left: 14px;
}

.chatdetail {
  padding-bottom: 92px;
}

.teamOver h2 {
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
  color: #ffffff;
  margin: 10px 0 0;
}

.teamOver h2 span {
  font-weight: 300;
  font-size: 14px;
  color: #969696;
}

.teamOver {
  margin-top: -110px;
  z-index: 9;
  position: relative;
}

.overview_tab .nav-link {
  font-weight: 300;
  margin: 0 10px;
  font-size: 14px;
  line-height: 17px;
  color: #06bd53;
  border: 0;
}

.overview_tab .nav-tabs .nav-item.show .nav-link,
.overview_tab .nav-tabs .nav-link.active {
  border: 0;
  border-radius: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  background: #06c153;
  color: #000000;
}

.tabDiv h6 {
  font-style: normal;
  margin-bottom: 18px;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #969696;
}

.tabDiv .box {
  background-color: #171a21;
  padding: 20px 10px;
  border-radius: 12px;
}

.tabDiv {
  margin-bottom: 30px;
}

.tabDiv .box h6 {
  margin: 15px 0 0;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #969696;
}

.tabDiv .box h6 span {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #ec1d63;
  margin-top: 5px;
}

.tabDiv .box .forward {
  background: #666666;
  font-weight: 500;
  font-size: 12px;
  color: #000;
  padding: 3px 7px;
  border-radius: 12px 0 12px 0;
  margin-left: -9px;
}

.tabDiv .box .forward img {
  width: 18px;
}

.tabDiv .box i {
  color: #969696;
}

.player_icon img {
  width: 56px;
  height: 56px;
  border-radius: 100%;
}

.news_icon {
  width: 136px;
  padding-right: 20px;
}

.news .text {
  width: calc(100% - 136px);
}

.news .text p {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #e3e3e3;
  margin: 0;
}

.news .text h6 {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ec1d63;
  margin: 0 0 10px;
}

.goal_account strong {
  font-size: 32px;
  font-weight: 500;
}

.goal_account span {
  font-size: 24px;
  color: #666666;
}

.goal_account {
  padding: 10px 0;
}

.match_card_head {
  background: #171a21;
  padding: 10px 15px;
  font-size: 11px;
  color: #efefef;
}

.match_card_footer {
  background: #171a21;
}

.match_card_body {
  background: #222731;
  padding: 15px 24px 15px 24px;
}

.main_of_match span {
  font-size: 9.5px;
  color: #969696;
}

.main_of_match .name {
  font-size: 9.5px;
}

.main_of_match .name img {
  margin-right: 7px;
}

.match_card_team_column figure {
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}

.match_card_team_column figure img {
  max-width: 64px;
  max-height: 52px;
}

.match_card_team_column h6 {
  font-size: 11.5px;
  color: #fff;
  font-weight: normal;
}

.match_card_team_column {
  flex: 0 0 70px;
  max-width: 70px;
  text-align: center;
  padding-top: 10px;
}

.team_goal_wrap {
  flex-grow: 1;
}

.match_card {
  border-radius: 16px;
  overflow: hidden;
}

.btn.green_outline_btn {
  border: 1px solid #06bd53;
  color: #06bd53;
  font-size: 12px;
}

.match_card_footer {
  padding: 10px;
}

.btn.green_outline_btn {
  border: 1px solid #06bd53;
  color: #06bd53;
  font-size: 12px;
}

.pts_value.text-pink {
  position: absolute;
  right: 0;
  padding: 0 15px 0 0;
  font-size: 18px;
  top: 50%;
  transform: translateY(-50%);
}

.live_tag {
  background: #e81d63;
  font-size: 11px;
  display: inline-block;
  padding: 1px 8px;
  border-radius: 20px;
  width: fit-content;
  color: #0f0f0f;
  font-weight: 600;
}

.match_time {
  margin-top: -5px;
  display: block;
  font-size: 10px;
  color: #808080;
}

.popular-value li {
  font-size: 15px;
}

.popular_pr span {
  font-size: 11px;
  color: #969696;
}

.time_tag {
  background: #969696;
  border-radius: 50px;
  font-size: 11px;
  color: #000;
  font-weight: 500;
  display: inline-block;
  padding: 2px 12px;
}

.popular-value {
  line-height: 1;
}

.none-_value {
  display: inline-block;
  width: 44px;
  height: 44px;
  border: 1px solid #565656;
  border-radius: 5px;
}

.mobile_footer {
  position: fixed;
  background: #000;
  z-index: 9;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 16px;
  justify-content: space-between;
}

.mobile_footer a {
  margin: 0 15px;
}

.bottom_space {
  padding-bottom: 70px;
}

.mobile_footer .Feed-center {
  margin-top: -6px;
  display: inline-block;
}

.mobile_footer a .green_icon:not(.mobile_footer a.active .green_icon) {
  display: none;
}

.mobile_footer .active .footer_light {
  display: none;
}

.mobile_theme_tabs .nav-link {
  font-size: 14px;
  color: #06bd53;
  padding: 2px 10px;
  border-radius: 100px;
  margin: 0;
  border: none;
}

.mobile_theme_tabs .nav-link.active {
  background: #06bd53;
  color: #000000;
}

.mobile_theme_tabs {
  border-bottom: none;
}

.mobile_create_compet figure img {
  max-width: 236px;
}

.mobile_create_compet figure {
  padding: 20px 0 0 0;
}

.mobile_ate_compition .form-control {
  background: transparent;
  border: 1px solid #929292;
  border-radius: 14px;
  margin-bottom: 14px;
  height: 48px;
  padding: 10px 20px;
  color: #fff;
  font-size: 14px;
}

.mobile_create_comption .join-text {
  font-size: 14px;
  color: #e1e1e1;
  text-align: center;
  display: block;
  padding: 15px 0;
}

.private_shape {
  text-align: center;
}

.mobile_ate_compition {
  padding: 15px 0;
}

.mobile_ate_compition .prize-btn {
  background-color: transparent !important;
  border: none;
  color: #06bd53 !important;
  font-size: 14px;
}

.private_circle {
  background: #151b2c;
  width: 92px;
  height: 92px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin: 24px auto 20px auto;
}

.mobile_inner-content .item-sl1 figure {
  width: 60px;
  height: 60px;
  margin-bottom: 5px;
}

.mobile_inner-content .item-sl1 h4 {
  font-size: 10px;
}

.miniHead span {
  position: relative;
  padding: 0 45px;
}

.miniHead span:before {
  position: absolute;
  content: "";
  width: 30px;
  background-color: #969696;
  top: 50%;
  left: 0;
  height: 1px;
}

.miniHead span:after {
  position: absolute;
  content: "";
  width: 30px;
  background-color: #969696;
  top: 50%;
  right: 0;
  height: 1px;
}

.table_icon {
  width: 29px;
  height: 29px;
  border-radius: 100%;
}

tr,
td {
  border-color: #444444;
  color: #fff;
}

table .text-success {
  font-weight: 500;
}

.create_comp {
  background-color: #141a26 !important;
  border-radius: 30px 30px 0 0;
  height: 580px !important;
  text-align: center;
}

.family_league {
  height: 414px !important;
}

.invite_send {
  height: 500px !important;
}

.accept_btn {
  background: #06bd53;
  border-radius: 8px;
  color: #000;
  text-align: center;
  width: 100%;
  border: 1px solid #06bd53;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
}

.cancel_btn {
  background: #141a26;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.35));
  text-align: center;
  color: #d11b1b;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d11b1b;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
}

.create_comp h2 {
  font-weight: 600;
  font-size: 20px;
  padding: 50px 80px 10px;
}

.create_comp h2 span {
  color: #e81d63;
  font-style: italic;
}

.create_comp h4 {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 90px;
}

.offcanvas-body {
  padding: 30px !important;
}

.chat h4 {
  font-weight: 500;
  font-size: 14px;
  color: #929292;
}

.chat_name img {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

.pnp_user a img {
  width: 23px;
  height: 22px;
}

.pnp_user li a {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 0;
  color: #06bd53 !important;
}

.chat li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat li a,
.chat li {
  color: #e1e1e1;
  font-weight: 600;
  font-size: 14px;
}

.create_comp h5 {
  font-weight: 400;
  font-size: 14px;
  color: #e1e1e1;
  margin-bottom: 30px;
}

.create_comp h3 {
  margin-top: 15px;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 14px;
}

.outline_btn {
  background: #141a26;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.35));
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #06bd53;
  border-radius: 7px;
  color: #06bd53;
  padding: 10px;
}

.trophy_level {
  padding: 2px 15px 2px 10px;
  background: #242e43;
  border-radius: 11px;
}

.trophy_level h2 {
  font-weight: 400;
  font-size: 14px;
}

.trophy_level h5 {
  font-weight: 600;
  font-size: 14px;
  color: #06bd53;
  font-style: italic;
}

.like {
  font-weight: 400;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 15px;
}

.familyLeague .send {
  bottom: 55px;
}

.familyLeague.bottom_space {
  padding-bottom: 150px;
}

.teamOver h2 i {
  font-size: 12px;
  font-weight: 300;
}

.tabDiv h5 {
  font-weight: 400;
  font-size: 12px;
  margin: 8px 0 0;
  line-height: 11px;
  text-align: center;
  color: #e1e1e1;
}

.bg-success {
  background: #06bd53 !important;
}

.fixed_btn {
  position: fixed;
  right: 25px;
  bottom: 80px;
}

.btn_pink {
  background: #e81d63;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 12px 50px;
  text-align: center;
  color: #000000;
}

.btn_pink img {
  position: absolute;
  right: 13px;
  top: 14px;
}

.bannerImg .chat_gem {
  bottom: auto;
  right: 35px;
  top: 25px;
}

.friendList {
  background: #141a26;
  border-radius: 11px;
  font-size: 14px;
  font-weight: 400;
}

.frienImg {
  width: 27px;
  height: 27px;
  border-radius: 100%;
}

.myInfo .form-control {
  border: 1px solid #929292;
  border-radius: 14px;
  font-weight: 400;
  font-size: 14px;
  color: #e1e1e1;
  background: #000;
  padding: 14px 18px;
}

.myInfo ::-webkit-input-placeholder {
  /* Edge */
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  color: #929292;
}

.myInfo :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  color: #929292;
}

.myInfo ::placeholder {
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  color: #929292;
}

.league_icon {
  padding: 35px 15px;
  text-align: center;
}

.league_icon img {
  border-radius: 100%;
  width: 64px;
  height: 64px;
  margin: 0 auto;
}

.feed_outer {
  background: #3c3f4a;
  border-radius: 13.8773px;
  padding: 15px 26px;
}

.feed_img img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.feed_outer h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #e1e1e1;
}

.feed_outer h6 span {
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #e1e1e1;
  mix-blend-mode: normal;
}

.feed_outer p {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #e1e1e1;
}

.feed_outer textarea {
  background: #505463;
  border-radius: 13.8773px;
  border: 0;
  color: #9ca3bb;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
}

.feed_outer .accordion-header button {
  background: none;
  border: 0;
  box-shadow: none;
  justify-content: flex-end;
  padding: 8px 0 8px 15px;
  position: relative;
  top: -38px;
  width: auto;
  float: right;
}

.feed_outer .accordion-header button:after {
  display: none;
}

.feed_outer .accordion-collapse {
  margin-top: 15px;
  border-top: 1px solid #828282;
  padding-top: 18px;
}

.reply .box {
  background: #525665;
  border-radius: 6.07134px;
  padding: 8px;
}

.reply .box h6 {
  font-size: 12px;
  line-height: 17px;
  color: #e1e1e1;
}

.likeComment a,
.reply a {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}

.match_card_team_column p {
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #929292;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.35);
  margin: 0;
  line-height: 17px;
}

.bg-none {
  background: none !important;
}

.teamOver .match_card_team_column {
  flex: 0 0 100px;
  max-width: 100px;
}

.teamOver .match_card_team_column h6 {
  font-size: 18px;
}

.pred {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #e1e1e1;
}

.pred span {
  background: #e81d63;
  border-radius: 6.73585px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding: 5px 10px;
}

.mobile_btn {
  padding: 12px 15px;
  font-size: 14px;
}

.my_competitions label {
  font-weight: 100;
  font-size: 18px;
  color: #efefef;
}

.my_competitions h3 {
  font-size: 22px;
  font-weight: 500;
}

.my_competitions .form-check {
  margin-left: 20px;
  margin-bottom: 40px;
}

.my_competitions .form-check-input {
  background-color: transparent;
  border: 1px solid #969696;
}

.my_competitions .form-check-input:checked[type="radio"] {
  background: #06bd53;
}

.my_competitions .form-check-input:focus {
  box-shadow: none;
}

.subs_box {
  border-radius: 8px;
  background: #222731;
}

.subs_box h4 {
  background-image: linear-gradient(to right,
      #462523 0,
      #cb9b51 22%,
      #f6e27a 45%,
      #f6f2c0 50%,
      #f6e27a 55%,
      #cb9b51 78%,
      #462523 100%);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.subs_box h3 {
  color: #e81d63;
}

.subs_box h2 {
  font-weight: 700;
  font-size: 18px;
  color: #929292;
}

.subs_box h2 span,
.subs_box h3 span,
.subs_box h4 span {
  color: #06bd53;
  font-size: 14px;
}

.subs_box ul li {
  font-weight: 400;
  font-size: 12px;
  color: #eeeeee;
  border-bottom: 1px solid #969696;
  padding: 10px;
}

.dark_gray_top {
  background: #171a21;
  border-radius: 10px 10px 0 0;
  display: flex;
}

.dark_gray_bottom {
  background: #171a21;
  border-radius: 0 0 10px 10px;
}

.subs_box button {
  background: #3e4556;
  border-radius: 8px;
  padding: 2px 34px;
  font-weight: 600;
  font-size: 12px;
  color: #000;
  border: 0;
}

.pink_bg {
  background-color: #e81d63 !important;
}

.gold_btn {
  background: radial-gradient(ellipse farthest-corner at right bottom,
      #fedb37 0%,
      #fdb931 8%,
      #9f7928 30%,
      #8a6e2f 40%,
      transparent 80%),
    radial-gradient(ellipse farthest-corner at left top,
      #ffffff 0%,
      #ffffac 8%,
      #d1b464 25%,
      #5d4a1f 62.5%,
      #5d4a1f 100%) !important;
}

.subs_box .accept_btn {
  background-color: #06bd53 !important;
}

.upload_photo textarea {
  padding-left: 52px;
}

.textarea_img {
  left: 10px;
  z-index: 0;
}

.upload_photo textarea:focus {
  box-shadow: none;
}

.upload_photo .img_upload {
  background: #242e43;
  border-radius: 11px;
  padding: 20px;
}

.upload_photo .img_upload input {
  width: 100%;
  height: 100% !important;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.img_upload h3 {
  font-weight: 500;
  font-size: 14px;
  color: #e1e1e1;
}

.img_upload h3 span {
  font-style: italic;
  color: #9ca3bb;
  font-weight: 300;
  font-size: 12px;
}

.img_upload .position-absolute {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.invite_friends h2 {
  font-weight: 600;
  font-size: 20px;
}

.invite_friends h5 {
  font-weight: 300;
  font-size: 14px;
  color: #e1e1e1;
}

.invite_friends .nav-tabs .nav-item.show .nav-link,
.invite_friends .nav-tabs .nav-link.active {
  background-color: transparent;
  color: #fff;
}

.invite_friends .nav-tabs {
  border: 0;
}

.invite_friends .nav-tabs .nav-item.show .nav-link,
.invite_friends .nav-tabs .nav-link.active {
  color: #06bd53;
  border: 0;
}

.invite_friends .nav-link {
  color: #fff;
  border: 0;
}

.invite_friends .ChatMobile input:focus {
  box-shadow: none;
}

.invite_friends .ChatMobile input {
  border: 1px solid #929292;
  border-radius: 14px;
  padding: 10px 40px;
  color: #fff;
}

.outer_exist {
  background: #1e2637;
  border-radius: 14px;
  padding: 30px;
}

.existing_list {
  max-height: 200px;
  padding-right: 30px;
  overflow-y: scroll;
}

.existing_list h2 {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 0;
}

.existing_list img:first-child {
  width: 32px;
  height: 32px;
}

.invite_friends .nav-item+.nav-item {
  border-left: 1px solid #e1e1e1;
}

.invite_friends .nav-link {
  margin: 0;
}

.bottom.fixed_btn {
  bottom: 130px;
}

.point_game.profile-cirlce svg {
  position: relative;
  width: 60px;
  height: 60px;
  transform: rotate(-90deg);
}

.point_game.profile-cirlce h3 {
  font-size: 14px;
}

.point_game.profile-cirlce svg circle {
  stroke-width: 4px;
}

.profile-cirlce {
  background: #222731;
  border-radius: 18px;
  padding: 25px;
  text-align: center;
  width: 100%;
}

.mobile-profile {
  background: #171a21 !important;
}

.feed-profile {
  max-width: 40px;
  border-radius: 100%;
  max-height: 40px;
  height: 40px;
}

.chatScroll {
  overflow-y: auto;
  max-height: 633px;
  padding: 0px 10px;
}

.edit-button {
  background-color: #06bd53;
  border-radius: 10px;
  text-align: center;
  padding: 2px 5px;
  cursor: pointer;
}

.leaderboard-profile {
  max-width: 40px;
  min-height: 40px;
  border-radius: 100%;
}

.swal2-styled.swal2-confirm {
  background-color: #047132 !important;
}

.swal2-styled.swal2-cancel {
  background-color: #8b113b !important;
}

.btn_pink:hover {
  background: #e81d63 !important;
  color: #000 !important;
}

.bannerImg>img {
  max-height: 260px;
}

.find_competition .tab-content {
  max-height: calc(100vh - 280px);
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: none;
}

.friendList>span {
  max-width: 60%;
}

.send-message-container input {
  background: #222731;
  border: 0;
  border-radius: 30px;
  color: #fff;
}

.comments-accordian button.accordion-button {
  position: absolute;
  top: 8px;
  left: 80px;
  right: auto;
  width: 100px;
  height: 24px;
}

.text-align-center{
  text-align: center;
}

.league-logo {
  max-width: 100px;
  max-height: 100px;
}
.friend-suggestion .existing_list .form-check-input[type="checkbox"] {
  padding: 0;
  right: 0;
}
.friend-suggestion .existing_list .form-check-inline {
  position: relative;
}
.find_competition .friendList>span {
  max-width: 160px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}