@media screen and (max-width: 1700px) {
  .common-upgrade-header .col-md-7 {
    flex: 0 0 auto;
    width: 50.333333%;
  }

  .common-upgrade-header .col-md-3 {
    flex: 1;
    width: 100%;
  }
}

@media screen and (max-width: 1600px) {
  .days_fix {
    font-size: 14px;
  }

  .header-right .nav-item.dropdown {
    margin-left: 16px;
  }

  .header-right ul li {
    margin-right: 6px;
    font-size: 13px;
  }

  .feed-card .accordion-button {
    right: 10px;
  }

  .common-user-head .d-flex {
    flex: 0 0 60%;
    max-width: 60%;
    margin-right: 42px;
  }

  .profile-cirlce {
    padding: 20px 15px;
  }

  .profile-dashboard.row {
    padding: 56px 7px;
  }

  .feedsidebar {
    padding: 64px 24px 36px 24px;
    width: 380px;
  }

  .feed-card .like-comment ul li {
    margin-right: 30px;
    font-size: 13px;
  }

  .post-card {
    padding: 15px 20px;
  }

  .right-sidebar-toggle .main-dashboard .main {
    width: calc(100% - 684px);
    padding-right: 24px;
  }

  .league_list {
    padding: 34px 24px 36px 24px;
  }

  .main_content_part {
    padding: 40px 0 0px 0;
  }

  .result_value_inner {
    min-width: 280px;
  }

  .standing_value li a,
  .team-cols-head li {
    min-width: 64px;
  }

  .competion-inner-listing>li {
    padding: 36px 20px;
  }

  .middle-user {
    flex: 0 0 auto;
    max-width: initial;
  }

  .competetion-right-panel .theme-btn {
    margin: 0 10px;
    min-width: 120px;
  }

  .competion-inner-listing li .competion-user-flex figcaption {
    padding: 0 11px;
  }

  .competion-inner-listing li .competion-user-flex h5 {
    font-size: 14px;
  }

  .competetion-right-panel .theme-btn {
    min-width: 80px;
  }

  .competetion-right-panel .gitt-card {
    font-size: 12px;
  }

  .competion-inner-listing li .competion-user-flex figure {
    width: 50px;
    height: 50px;
  }

  .competion-inner-listing li {
    padding: 40px 15px;
  }

  .gift-price-listing li {
    padding: 0;
  }

  .competetion-right-panel .theme-btn {
    min-width: 80px;
  }
}

@media screen and (max-width: 1400px) {
  .subscription-content {
    padding: 20px 8px;
  }

  .finished-outer .brand_cols {
    flex: 0 0 21%;
    max-width: 21%;
  }

  .friend-goal-list ul li {
    font-size: 13px;
  }

  .team_campare_value figure {
    width: 60px;
    height: 60px;
  }

  .team_campare_value figure {
    width: 60px;
    height: 60px;
  }

  .header-right ul li {
    margin-right: 12px;
  }

  .feed-card .accordion-header {
    margin-bottom: 32px;
  }

  .site_form.login_form {
    padding: 25px 50px;
  }

  .login-space {
    padding: 35px 0;
  }

  .burger {
    padding-bottom: 21px;
    padding-top: 111px;
  }

  .date_slide .slick-prev {
    left: 0px;
  }

  .date_slide .slick-next {
    right: 0px;
  }

  .common-user-head .premimum h4 {
    justify-content: start;
    margin-top: 20px;
  }

  .common-user-head .premimum {
    padding: 6px 7px;
  }

  .common-user-head {
    align-items: normal;
    flex-direction: column;
  }

  .common-user-head .d-flex {
    flex: 0 0 100%;
    max-width: 100%;
    margin-right: 0px;
    border-right: none;
    border-bottom: 1px solid #3b3434;
    padding-bottom: 15px;
  }

  .common-user-head figure {
    width: 40px;
    height: 40px;
  }

  .premimum img {
    max-width: 27px;
  }

  .common-upgrade-header .offset-md-2 {
    margin-left: 10.666667%;
  }

  .common-user-head figcaption {
    width: calc(100% - 40px);
    padding-left: 16px;
  }

  .common-user-head figcaption h4 {
    font-size: 17px;
  }

  .common-upgrade-header {
    margin-bottom: 30px;
  }

  .main-wrapper-outer {
    padding: 20px;
  }

  .left-sidebar {
    width: 240px;
    padding: 20px 20px 24px 20px;
  }

  .left-sidebar .logo-panel {
    margin-bottom: 50px;
  }

  .sidebar-menu li {
    margin-bottom: 15px;
  }

  .sidebar-menu li a {
    padding: 15px;
  }

  .upgrade-btn a {
    padding: 10px 20px;
  }

  .feedsidebar {
    padding: 64px 24px 36px 24px;
    width: 320px;
  }

  .feed-title h3 {
    font-size: 22px;
  }

  .theme-btn {
    padding: 10px 16px;
    font-size: 14px;
  }

  .post-editor.d-flex.align-items-center {
    margin-bottom: 20px;
  }

  .comment-sec li {
    margin-bottom: 20px;
  }

  .days_fix {
    font-size: 14px;
    padding: 0 8px;
  }

  .date_slide {
    padding: 13px 10px;
  }

  .right-sidebar-toggle .main-dashboard .main {
    width: calc(100% - 540px);
    padding-right: 24px;
    margin-left: 240px;
  }

  .finished_tag,
  .right_tag {
    top: 15px;
  }

  .brand_cols strong {
    font-size: 16px;
  }

  .goals-count span,
  .team_goals_value span {
    font-size: 26px;
    padding: 7px 5px;
  }

  .league_detail_row .theme-btn {
    padding: 10px 24px;
  }

  .comingsoon_time ul li {
    padding: 0 15px;
  }

  .comingsoon_time ul li .days {
    font-size: 19px;
  }

  .comment-sec li ul {
    padding-left: 15px;
    list-style: none;
  }

  .main-dashboard .main {
    padding-right: 0px;
    margin-left: 240px;
    width: calc(100% - 240px);
  }

  .result_value_cols {
    padding: 0 15px;
  }

  .result_value_inner li strong {
    font-size: 22px;
  }

  .result_value_inner {
    min-width: 215px;
  }

  .team_compare_value_tag {
    padding: 15px 20px;
    min-width: 94px;
  }

  .team_compare_value {
    padding: 14px 24px;
    height: auto;
  }

  .team_cols_list {
    padding: 15px 20px;
  }

  .goals_list_logo .predication_value h6 {
    font-size: 15px;
  }

  .standing_list_left .predication_tag {
    width: 44px;
    height: 44px;
    flex: 0 0 44px;
  }

  .standing-data_list_main .team_cols_list {
    min-width: 880px;
  }

  .middle-user {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .competetion-right-panel .theme-btn {
    margin: 0 12px;
  }

  .competion-inner-listing li {
    padding: 45px 24px 24px 24px;
  }

  .competetion-right-panel {
    flex: 0 0 44%;
    max-width: 44%;
  }

  .middle-user {
    flex: 0 0 40px;
    max-width: 40px;
  }

  .competion-inner-listing li {
    flex-wrap: wrap;
  }


  .competion-inner-listing li .competion-user {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
    padding-bottom: 15px;
  }

  .competetion-right-panel {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: start;
  }

  .competetion-right-panel .gitt-card {
    flex-grow: 0;
  }

  .gift-price-listing li {
    padding: 0;
  }

  .goals-count span {
    margin: 0px 6px;
  }
}

@media screen and (max-width: 1199px) {
  .common-inner-sec {
    padding: 17px 0px;
  }

  .standing-data_list_main .team_cols_list {
    min-width: 695px;
  }

  .feed-card .accordion-header {
    margin-bottom: 11px;
  }

  .message-btn {
    right: 62px;
  }

  .header-right .dropdown-menu.show {
    transform: translate(11px, 39px) !important;
  }

  .subscription-content ul.list-unstyled {
    height: 216px;
    overflow-y: auto;
  }

  .subscription-sec {
    padding: 45px 19px;
  }

  .common-upgrade-header .upgrade-btn {
    padding-top: 0px;
  }

  .common-upgrade-header .offset-md-2 {
    margin-left: 3.666667%;
  }

  .common-upgrade-header .col-md-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }

  .main-wrapper-outer {
    padding: 15px;
  }

  .left-sidebar {
    width: 200px;
    padding: 15px;
    border-radius: 10px;
  }

  .sidebar-menu li a {
    padding: 12px;
    font-size: 13px;
    border-radius: 8px;
  }

  .sidebar-menu li a img {
    margin-right: 8px;
    max-width: 18px;
  }

  .upgrade-btn a {
    padding: 10px 15px;
  }

  .upgrade-btn img {
    margin-right: 7px;
    max-width: 15px;
  }

  .left-sidebar .logo-panel {
    margin-bottom: 36px;
  }

  .upgrade-btn {
    padding-top: 15px;
  }

  .feedsidebar {
    padding: 50px 15px 20px 15px;
    width: 400px;
  }

  .cross-icon {
    top: 10px;
    right: 10px;
  }

  .feed-title h3 {
    font-size: 20px;
  }

  .theme-btn {
    padding: 8px 15px;
    font-size: 13px;
    border-radius: 8px;
  }

  .league_list {
    border-radius: 8px;
    padding: 34px 15px 50px 15px;
  }

  .header-left .nav-item {
    min-width: 186px;
    padding: 10px 15px;
    font-size: 13px;
  }

  .notification {
    width: 22px;
  }

  .right-sidebar-toggle .main-dashboard .main {
    width: calc(100% - 200px);
    /* padding: 24px 15px 24px 15px; */
    margin-left: 200px;
  }

  .finished_tag,
  .right_tag {
    top: 10px;
    padding: 3px 10px;
    border-radius: 5px;
  }

  .finished_tag {
    font-size: 11px;
    left: 10px;
  }

  .right_tag {
    right: 10px;
  }

  .brand_cols figure {
    width: 62px;
    height: 62px;
  }

  .goals-count span {
    margin: 0;
    font-size: 20px;
    padding: 5px 5px;
  }

  .league_detail_row .theme-btn {
    padding: 8px 15px;
  }

  .league_center_cols {
    padding: 0 10px;
  }

  .league_center_cols h2 {
    font-size: 16px;
  }

  .goals-count {
    padding: 20px 0 24px 0;
  }

  .post-card {
    padding: 15px;
  }

  .feed-card p {
    font-size: 14px;
  }

  .feed-card .like-comment ul li {
    margin-right: 15px;
  }

  .feed-card .like-comment ul li img {
    max-width: 16px;
  }

  .message-btn img {
    max-width: 62px;
  }

  .middle-circle,
  .small-circle {
    width: 28px;
    height: 28px;
  }

  .comment-sec li:last-child {
    margin-bottom: 0;
  }

  .comment-sec li ul {
    padding-left: 0;
  }

  .post-editor input[type="text"] {
    padding: 8px 15px;
  }

  .profile-outer {
    width: 36px;
    height: 36px;
  }

  .notification span {
    right: -8px;
  }

  .main_content_part {
    padding: 24px 0 0px 0;
  }

  .header-right ul li a {
    font-size: 14px;
  }

  .header-right ul li {
    margin-right: 0;
    padding: 10px;
  }

  .header-right .nav-item.dropdown {
    margin-left: 20px;
  }

  .header-right>ul {
    margin-right: 10px;
  }

  .main-dashboard .main {
    padding-right: 0px;
    width: calc(100% - 200px);
    margin-left: 200px;
  }

  .team_compare_value {
    padding: 10px 15px;
    border-radius: 10px;
  }

  .team_compare_value_tag {
    padding: 10px 15px;
    min-width: initial;
    border-radius: 10px;
  }

  .goals_list_logo .predication_value h6 {
    font-size: 14px;
  }



  /* front-page */

  .btn-app-store,
  .btn-android-store {
    padding: 8px 24px 13px 24px;
  }

  .prediction-sec .slick-next {
    right: -10px;
    z-index: 9;
  }

  .prediction-sec .slick-prev {
    left: -10px;
    z-index: 9;
  }

  .find-competetion-list .competetion-right-panel {
    justify-content: space-between;
  }





}

@media screen and (max-width: 991px) {
  .main-wrapper-outer {
    padding: 0px;
  }

  .left-sidebar {

    height: 100%;
  }

  .competetion-right-panel {
    justify-content: center;
  }

  .left-sidebar {
    border-radius: 0;
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    transition: all ease 0.3s;
    -webkit-transition: all ease 0.3s;
    transform: translateX(-100%);
    box-shadow: 0 0 24px rgb(0 0 0 / 30%);
  }

  .main-dashboard .main {
    padding: 15px;
    margin-left: 0px;
    width: 100%;
  }



  .right-sidebar-toggle .main-dashboard .main {
    width: 100%;
    padding: 15px;
    margin-left: 0px;
  }

  .post-card {
    border-radius: 10px;
  }

  .sidebar_toggle {
    width: 60px;
    height: 60px;
    padding: 10px;
    right: -10px;
    background: #30405e;
  }

  .league_list {
    padding: 34px 15px 50px 15px;
  }

  .left-sidebar-toggle .left-sidebar {
    transform: translate(0);
    width: 250px;
  }

  .left-sidebar-toggle .left_sidebar_toggle {
    transform: translateX(232px);
    z-index: 999;
    position: fixed;
  }

  .gift-price-listing {
    right: auto;
    left: 0;
    border-radius: 0px 25px 25px 25px;
  }

  .message-btn {
    z-index: 99;
  }

  .feedsidebar-chat .common-tab {
    z-index: 99;
    right: 70px;
  }



  /* front-page */

  .btn-app-store,
  .btn-android-store {
    padding: 8px 24px 13px 24px;
  }

  .signup-col h2 {
    font-size: 22px;
  }

  .signup-col h5 {
    font-size: 17px;
  }

  .trd-section h5 {
    font-size: 16px;
  }

  .trd-section h2 {
    font-size: 20px;
  }

  .htp {
    font-size: 32px;
  }

  .line-div:after,
  .line-div:before {
    width: 140px;
  }

  .prediction-sec {
    padding: 3rem 0;
  }

  .scnd-section {
    text-align: center;
  }

  .btn-app-store,
  .btn-android-store {
    width: 80%;
    display: inline-block;
  }

  .prediction-sec {
    text-align: center;
  }

  .inning-stage {
    justify-content: end;
  }






}

@media screen and (max-width: 767px) {
  .common-upgrade-header .upgrade-btn {
    margin: 10px auto;
    display: block;
  }

  .slide-section a {
    font-size: 27px;
    color: #fff;
  }

  .slide-content {
    width: 100%;
  }

  .competition-prize {
    font-size: 14px;
  }

  .header-right ul li a {
    font-size: 12px;
  }

  .header-right .nav-item.dropdown {
    margin-left: -1px;
  }

  .header-predication {
    width: 21px;
    height: 21px;
    flex: 0 0 21px;
  }

  .friend-predict-wrapper {
    flex-direction: column;
  }

  .custome_form_grp {
    margin-bottom: 2rem;
  }

  .site_form.login_form h1 {
    margin-bottom: 17px;
  }

  .match-leadge-scoller,
  .competion-inner-listing,
  .global-team-outer,
  .my-profile_friends,
  .predict-scoller,
  .standing-sec-scoller {
    max-height: 635px;
  }

  .profile-dashboard.row {
    padding: 31px 7px;
  }

  .result_value_inner {
    min-width: 200px;
  }

  .result_value_inner li strong {
    font-size: 18px;
  }

  .result_value_cols {
    padding: 0 10px;
  }

  .league_comment_list {
    padding: 24px 0 0 0;
  }

  .league_comment_section>ul {
    padding-right: 15px;
  }

  .message-btn img {
    max-width: 50px;
  }

  .create-compet {
    padding: 32px 0px;
  }

  .listing-heading .theme-btn {
    min-width: 200px;
  }

  .common-modal .modal-dialog {
    max-width: 550px;
  }

  .common-modal .modal-body {
    padding: 30px;
  }

  .common-modal .modal-footer {
    padding: 20px 30px;
  }

  .common-modal .common-tab .tab-content {
    padding: 20px 20px 15px;
  }

  .pic_upload {
    width: 100%;
  }

  .competion-inner-listing>li {
    padding: 24px 20px 10px 20px;
  }



  /* front-page */
  .header-right {
    margin-bottom: 0;
  }

  .home-header {
    padding: 15px 0;
  }

  .scnd-section {
    padding: 2rem 0rem 5rem 0rem;
  }

  .line-div:after,
  .line-div:before {
    display: none;
  }



  .crcl-d-2-3 .crcl-ds-3 {
    top: 0;
  }

  .crcl-d-2-0 {
    max-width: 230px;
    margin: 0 auto 40px auto;
  }


  .signup-col .btn-signup,
  .prediction-sec .btn-play {
    max-width: 250px;
    margin: auto;
  }

  .signup-col:last-child {
    padding-bottom: 0;
  }

  .prediction-card .card-body {
    padding: 10px 20px !important;
  }

  .prediction-sec {
    padding: 24px 0;
  }

  .prediction-sec h1 {
    font-size: 32px;
  }

  .border-right {
    border-right: none;
  }

  .footer-sec h4 {
    font-size: 18px;
  }

  .prediction-sec h4 {
    font-size: 16px;
  }

  .scnd-section h1 {
    font-size: 32px;
  }

  .scnd-section p {
    font-size: 16px;
  }

  .scnd-section h2 {
    font-size: 16px;
  }

  .slide-section {
    padding: 3rem 0rem 2rem 0rem;
    background: #000;
  }

  .trd-section {
    background-color: #000000;
    overflow-x: hidden;
  }

  .signup-col {
    margin: 24px 0;
  }

  .col-md-4.signup-col.cntr-div {
    margin-bottom: 0;
  }

  .login-space {
    padding: 0;
  }

  .burger {
    padding-bottom: 60px;
    padding-top: 150px;
    height: 100vh;
  }

  .front_menu_sidebar {
    width: 350px;
    padding: 50px 15px 24px 90px;
  }

  .competion-inner-listing ul li {
    flex: 0 0 100%;
    max-width: calc(100% - 20px);
    margin: 10px;
  }


}

@media screen and (max-width: 575px) {
  .competetion-right-panel {
    justify-content: initial;
  }

  .edit-slot-sec a img {
    max-width: 20px;
  }

  .edit-slot-sec a {
    margin-right: 8px;
  }

  .slide-content {
    position: initial;
    transform: translateX(0);
    margin-top: -87px;
    position: relative;
    left: 0;
    top: 7px;
  }

  .slide-section a {
    font-size: 18px;
    color: #fff;
    line-height: 28px;
  }

  .competion-inner-listing li .competion-user {
    flex-direction: column;
    align-items: normal;
  }

  .league_list {
    padding: 35px 15px 10px 15px;
    position: relative;
  }

  .friend-predict-value .brand_cols {
    flex: 0 0 28%;
    max-width: 28%;
  }

  .friend-predict-value .team_goals_value {
    display: flex;
  }

  .rank-margin {
    margin-left: 0px;
  }

  .predication_value h6 {
    font-size: 13px;
  }

  .predication_tag {
    margin-right: 7px;
  }

  .custome_select.delete-icon-pre {
    position: absolute;
    right: 0;
    /* bottom: 0; */
    top: -36px;
  }

  .standing-data_list_main .team_cols_list {
    min-width: 562px;
  }

  .competion-inner-listing li .competion-user-flex {
    flex: 0 0 75%;
    max-width: 75%;
    margin-bottom: 15px;
  }

  .competetion-right-panel .theme-btn img,
  .competetion-right-panel .more-detail img {
    display: none;
  }

  .competetion-right-panel .theme-btn {
    margin: 0 8px;
  }

  .competetion-right-panel {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .competetion-right-panel .more-detail {
    min-width: auto;
    flex: 1 !important;
  }

  .competion-inner-listing li {
    padding: 15px;
  }

  .history-result-form {
    margin: 0px;
    display: flex;
    flex-direction: column;
    height: 42%;
    position: absolute;
    justify-content: space-between !important;
    top: 32%;
    display: flex;
    transform: translateY(-50%);
    right: 21px;
  }

  .common-upgrade-header .col-md-7 {
    width: 100%;
  }

  .feed-card .like-comment ul li {
    font-size: 11px;
  }

  .header-right .dropdown-menu.show {
    transform: translate(13px, 39px) !important;
  }

  .notification-dropdown-wrapper .dropdown-menu {
    width: 255px;
    margin-right: 0px;
  }

  .feedsidebar {
    width: 100%;
  }

  .competion-inner-listing li .competion-user .inning-stage span {
    padding: 5px 12px;
  }

  .header-left .dropdown-menu {
    min-width: auto;
  }

  .header-right .nav-item.dropdown:first-child {
    margin-left: 0px;
  }

  .days_fix {
    font-size: 13px;
    padding: 0 6px;
  }

  .about-para p {
    font-size: 14px;
    line-height: 25px;
  }

  .profile-form .form-control1 {
    padding: 7px 0px;
    font-size: 15px;
  }

  .subscription-sec {
    padding: 45px 6px;
  }

  .profile-form .form-label {
    flex: 1 1;
    max-width: 100%;
    text-align: left;
    margin-bottom: 14px;
  }

  .profile-form .form-control {
    border-radius: 9px;
    padding: 14px 25px;
    flex: 1 1;
    font-size: 16px;
  }

  .profile-form .form-group {
    flex-direction: column;
    align-items: normal;
  }

  .common-inner-sec {
    padding: 17px 10px;
  }

  .main-dashboard .main .header {
    flex-direction: column-reverse;
    align-items: initial;
  }

  .main-dashboard .main .header .header-left {
    width: 100%;
    padding-top: 10px;
  }

  .left_sidebar_toggle {
    position: absolute !important;
    top: 15px;
    left: 15px;
    height: 38px;
    width: 40px;
    padding: 5px;
    border-radius: 5px;
  }

  .main_content_part {
    padding-top: 10px;
  }

  .date_slide {
    margin: 20px 0;
  }

  .league_detail_row {
    flex-direction: column;
    align-items: stretch;
  }

  .league_center_cols {
    padding: 20px 15px;
    border-top: 1px solid #2a303c;
    border-bottom: 1px solid #2a303c;
    margin: 10px 0;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .league_center_cols.mobile-cols {
    padding: 0px;
    border-bottom: none;
  }

  .brand_cols {
    padding: 10px 0;
    flex: 0 50%;
    max-width: 50%;
  }

  .goals-count span {
    margin: 0 10px;
  }

  .brand_cols figure {
    width: 55px;
    height: 55px;
  }

  .header-right .nav-item.dropdown {
    margin-left: 4px;
  }

  .header-right ul li {
    margin-right: 0;
    padding: 0;
  }

  .header-right ul li a {
    padding: 10px 5px;
  }

  .brand_cols strong,
  .league_center_cols h2 {
    font-size: 16px;
  }

  .result_value_inner {
    min-width: 240px;
  }

  .league_comment_section>ul {
    padding-right: 0;
  }

  .league_readmore {
    padding: 20px 0 0 0;
  }

  .league_comment_textarea {
    padding-left: 10px;
    margin-right: 10px;
  }

  .sidebar_toggle {
    width: 50px;
    height: 50px;
  }

  .league_comment_list {
    padding: 15px 0 0 0;
  }

  .down_arrow {
    bottom: 15px;
  }

  .league_comment_section .form-control {
    padding: 9px 20px;
    height: 40px;
  }

  .team_compare_value,
  .team_compare_value_tag {
    margin-top: 20px;
    width: 71%;
    border-radius: 6px;
    font-size: 15px;
  }

  .team_campare_value {
    max-width: 280px;
    padding: 15px 0;
  }

  .team_campare_value .brand_cols figure {
    width: 64px;
    height: 64px;
  }

  .team_goals_value {
    padding: 0 10px;
  }

  .predication_tag_row {
    flex-direction: column;
    position: relative;
  }

  .predication_tag_row .green_droap_down {
    margin-top: 20px;
  }

  .team_cols_list {
    border-radius: 10px;
    margin: 15px 0;
    position: relative;
  }

  .owner-text {
    position: absolute;
    bottom: 0;
    left: 24px;
  }

  .standing_list-main .team_compare_value {
    width: 80px;
    margin-top: 0;
  }

  .standing_value li a,
  .standing_list-main .team_compare_value {
    min-width: 50px;
    height: 50px;
    font-size: 14px;
    border-radius: 10px;
    margin: 0 5px;
    width: auto;
  }

  .team-cols-head li {
    min-width: 50px;
    font-size: 14px;
    margin: 0 5px;
  }

  .ml-20 {
    margin-left: 5px;
  }

  .standing_list-main .team_compare_value span {
    padding: 0;
  }

  .listing-heading .theme-btn {
    margin: 15px 0;
    display: block;
  }

  /* .competetion-right-panel {
    flex-wrap: wrap;
    justify-content: center;
  } */

  /* .competetion-right-panel .theme-btn {
    flex: 0 0 100%;
  } */

  .competetion-right-panel .more-detail {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    margin: 5px 10px;
    padding: 10px;
  }

  .competetion-right-panel .gitt-card {
    margin: 6px;
  }

  .competion-inner-listing>li {
    margin-bottom: 15px;
  }

  .create-compet h3 {
    font-size: 22px;
  }

  .date_global-slider .nav-tabs .nav-link {
    background-color: #222731;
    border-radius: 4px;
    margin: 2px;
    padding: 5px 10px;
    font-size: 13px;
  }

  .date_global-slider .nav-tabs {
    justify-content: center;
  }

  .create-compet {
    padding: 20px 0px;
  }

  .global-team-outer .predication_tag {
    width: 36px;
    height: 36px;
    flex: 0 0 36px;
  }

  .prediction_wrapper {
    padding: 20px 0 15px 0px;
  }

  .competetion-right-panel .gitt-card {
    margin-bottom: 15px;
  }

  .compition_list_main .team_compare_value {
    max-width: 150px;
    margin: 15px auto;
  }

  .compition_list_main .team_compare_value_tag {
    width: 50%;
    margin: 12px auto 0px;
    display: block;
  }

  .compition_list_main .team_cols_list .row {
    text-align: center;
  }

  /* .compition_list_main .team_cols_list .goals_list_logo {
    justify-content: center;
  } */

  .common-modal .modal-body {
    padding: 20px;
  }

  .common-modal .modal-footer {
    padding: 10px 20px;
  }

  .common-modal .modal-body .form-control {
    height: 48px;
    padding: 10px;
    border-radius: 10px;
  }

  .inner-content {
    margin-top: 24px;
  }

  .common-modal .modal-content {
    border-radius: 16px;
  }

  .modal-body-heading h2 {
    font-size: 18px;
    line-height: 1.4;
  }

  .modal-body-heading p {
    margin-bottom: 0;
  }

  .friend-suggestion {
    background: #1e2637;
    border-radius: 10px;
    padding: 20px 0 7px 20px;
  }

  .friend-suggestion ul li {
    margin-bottom: 10px;
  }

  .common-modal .common-tab .nav.nav-tabs .nav-item button {
    padding: 8px;
    font-size: 12px;
  }

  .modal-parellal-heading img {
    margin-right: 4px;
    max-width: 13px;
  }

  .common-modal .modal-dialog {
    max-width: 100%;
  }

  .common-modal .modal-header {
    align-items: flex-start;
  }

  .result_value_inner {
    padding: 15px 13px;
    margin: 10px 0;
  }

  .logout_main p {
    font-size: 13px;
  }

  .message-btn {
    z-index: 999;
    bottom: 3px;
  }

  .feedsidebar-chat .common-tab {
    right: 11px;
    z-index: 99;
  }



  /* front-page */
  .slide-section {
    padding: 2rem 0rem 2rem 0rem;
    background: #000;
  }

  .footer-sec p {
    font-size: 14px;
  }

  .footer-sec h4 {
    font-size: 16px;
  }

  .search-hed img {
    max-height: 16px;
    padding: 0;
  }

  .search-hed {
    font-size: 13px;
  }

  .prediction-sec h1 {
    font-size: 24px;
  }

  .prediction-sec h2 {
    font-size: 18px;
  }

  .btn-app-store,
  .btn-android-store {
    width: 100%;
  }

  .site_header .navbar-brand {
    width: 116px;
    margin-right: 0;
  }

  .site_header .nav-link {
    padding: 6px 10px;
    font-size: 12px;
  }

  .header-right {
    padding-left: 0;
  }

  .scnd-section h1,
  .htp,
  .htp span {
    font-size: 24px;
  }

  .site_form.login_form {
    padding: 20px 15px;
  }

  .site_form.login_form h1 {
    font-size: 1.4em;
  }

  .forgot {
    margin: 0;
  }

  .burger {
    padding-bottom: 40px;
    padding-top: 130px;
  }

  .site_form.login_form .form-check {
    margin: 10px 0;
  }

  .site_form.login_form h1 {
    margin-bottom: 24px;
  }

  .front_menu_sidebar {
    width: 280px;
  }

  .front_menu_link li a {
    font-size: 16px;
  }

  .menu_head button img {
    max-width: 12px;
  }





}