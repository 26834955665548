.not-found {
  align-items: center;
  text-align: center;
  margin-top: 15%;
}

.logoLeagues {
  height: 70px;
  width: 70px;
}

.loginContainer {
  height: 100%;
}

.forgot_title {
  color: white;
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 1.5rem;
  font-weight: 600;
}

button.btn-join.btn.btn-primary {
  background: #06bd53;
  padding: 8px 30px 8px 26px;
  border-radius: 10px;
  color: #000;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 14px;
  transition: 0.6s ease;
  cursor: pointer;
  border: 1px solid transparent;
}

.mb-6 {
  bottom: 6px !important;
}

.css-b62m3t-container {
  width: 100% !important;
}

.premier-accordian .accordion-item,
.premier-accordian .accordion-button {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.premier-accordian .accordion-button::after {
  margin: 0 auto;
  background-image: url(../public/assets/images/greearrow.svg);
}

.premier-accordian .accordion-button {
  padding-bottom: 0px !important;
}

.result_value_inner li strong {
  color: #fff;
}

.user_chat_image {
  width: 35px;
  height: 35px;
  border-radius: 100px;
  margin: 0 10px;
}



/* 10-13-22 comment accordian */
.comments-accordian .accordion-item,
.premier-accordian .accordion-button {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.comments-accordian .accordion-button::after {
  margin: 0 auto;
  content: "";
  background-image: none;
  color: #06bd53;
  font-size: 14px;
  width: 74px;
  display: block;
  right: 0;
}

.comments-accordian .accordion-button {
  padding-bottom: 0px !important;
}


.comments-accordian .like-comment ul li {
  margin-right: 30px;
}

.comments-accordian .like-comment ul li:last-child {
  margin-right: 0px;
}

.comments-accordian .accordion-button {
  color: #fff;
  font-size: 13px;
  width: auto;
  display: block;
  text-align: right;
  top: 0px;
  z-index: 1;
  right: 118px;
}

/* .comments-accordian .accordion-button:not(.collapsed)::after{
  content: "Hide All";
} */
.comments-accordian .like-comment {
  position: absolute;
  top: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.extra-package {
  padding: .5rem !important;
  background: #222731;
  border-radius: 14px;
}



button.kep-login-facebook.metro.fblogin {
  color: blue;
  border-radius: 51px;
  background: none;
  border: none;
  font-size: 24px;
  height: 32px;
  width: 32px;
  padding: 0;
}

.fa-stack-1x,
.fa-stack-2x {
  position: relative !important;
  left: 0;
  width: 100%;
  text-align: center;
}


.social-buttons {
  align-items: center !important;
  justify-content: flex-end !important;
}

.social-buttons p {
  margin-bottom: 0;
}

.forgot-social {
  padding: 0 0 15px 0;
}

.groups {
  text-align: center;
  justify-content: center;
  display: flex;
}

.sidebar-menu li a.active img {
  filter: brightness(0) !important;
}

.frined-prediction-match-status {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 11px;
}

.frined-match-status {
  background: #3d4e6f;
  border-radius: 6px;
  padding-block: 1px;
  padding-inline: 5px;
  font-family: 'futura-500-italic';
  font-size: 12px
}

.delete-prediction-icon {
  margin-right: 15px;
}

.competition-prize {
  font-family: "futuralight_italic";
  font-size: 15px;
}

.send-message-btn {
  background: green;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
}

.send-message-btn:hover {
  background: blue;
}

.send-message-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-search-input {

  width: calc(100% - 36px) !important;
}

.sponsored-class {
  height: 20px;
  left: 20.47%;
  right: 75.52%;
  top: calc(50% - 20px/2 - 522px);
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #E81D63;
}

.text-start {
  text-align: -webkit-auto !important;
}

.tc-page {
  max-height: 580px !important;
}

.all-prizes span {
  margin-left: 6px;
}

.swal2-popup {
  background: #222731 !important;
}

h2#swal2-title {
  color: white !important;
}

.own {
  border: 2px solid;
  border-color: green;
  border-radius: 23px;
}

.cursor {
  cursor: pointer;
}