body {
  background-color: #000;
  font-family: "Rubik", sans-serif;
  color: #e1e1e1;
}

.green-color {
  color: #06bd53;
}

img {
  max-width: 100%;
}

.theme-btn {
  background: #06bd53;
  border-radius: 8px;
  border-color: #06bd53;
  padding: 12px 19px;
  font-weight: 500;
  color: #000;
  transition: 0.4s;
}

.theme-btn:hover {
  background-color: #e81d63 !important;
  border-color: #e81d63 !important;
}

.theme-btn:hover {
  color: #fff;
}

@font-face {
  font-family: "futurabold";
  src: url("../font/futura_bold_font-webfont.woff2") format("woff2"),
    url("../font/futura_bold_font-webfont.woff") format("woff");
}

@font-face {
  font-family: "futurabold_italic";
  src: url("../font/futura_bold_italic_font-webfont.woff2") format("woff2"),
    url("../font/futura_bold_italic_font-webfont.woff") format("woff");
}

@font-face {
  font-family: "futura-500";
  src: url("../font/futura_book_font-webfont.woff2") format("woff2"),
    url("../font/futura_book_font-webfont.woff") format("woff");
}

@font-face {
  font-family: "futura-500-italic";
  src: url("../font/futura_book_italic_font-webfont.woff2") format("woff2"),
    url("../font/futura_book_italic_font-webfont.woff") format("woff");
}

@font-face {
  font-family: "futuralight";
  src: url("../font/futura_light_bt-webfont.woff2") format("woff2"),
    url("../font/futura_light_bt-webfont.woff") format("woff");
}

@font-face {
  font-family: "futuralight_italic";
  src: url("../font/futura_light_italic_font-webfont.woff2") format("woff2"),
    url("../font/futura_light_italic_font-webfont.woff") format("woff");
}

/* font */

/* left-sidebar */

.left-sidebar {
  background: #141a26;
  border-radius: 24px;
  width: 304px;
  padding: 37px 39px 37px 29px;
  position: fixed;
  height: 96%;
}

.sidebar-menu li a {
  text-decoration: none;
  color: #828282;
  font-size: 14px;
  line-height: 20px;
  padding: 21px 20px;
  display: block;
  border-radius: 12px;
  transition: 0.3s;
  font-weight: 500;
  cursor: pointer;
}

.left-sidebar .logo-panel {
  margin-bottom: 90px;
}

.sidebar-menu li a:hover img {
  filter: blur(0);
}

.sidebar-menu li {
  margin-bottom: 30px;
}

.sidebar-menu li a:hover img {
  filter: brightness(0);
}

.sidebar-menu li a.active,
.sidebar-menu li a:hover {
  background: #e81d63;
  color: #000;
}

.sidebar-menu li a img {
  margin-right: 12px;
}

.upgrade-btn a {
  text-decoration: none;
  color: #06bd53 !important;
  display: block;
  font-weight: 600;
  font-size: 12px;
  padding: 12px 38px;
  border: 1px solid #06bd53;
  border-radius: 8px;
  transition: 0.4s;
  text-align: center;
  cursor: pointer;
}

.upgrade-btn a:hover {
  background: #06bd53;
  color: #382e2e !important;
}

.upgrade-btn a:hover img {
  filter: brightness(0);
}

.upgrade-btn img {
  margin-right: 13px;
}

.main-dashboard {
  display: flex;
}

.main-dashboard .main {
  width: calc(100% - 304px);
  padding: 20px 24px 34px 24px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  margin-left: 304px;
}

/* left-sidebar */

/* top-header */

.header-right ul li a {
  text-decoration: none;
  color: #06bd53;
  font-weight: 700;
  display: block;
}

.header-right ul li {
  display: inline-block;
  margin-right: 36px;
}

.notification span {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: #d11b1b;
  right: -17px;
  border-radius: 100%;
  top: -10px;
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.header-right .nav-item.dropdown {
  margin-left: 35px;
}

.header-right .dropdown-toggle::after {
  display: none;
}

.header-right .dropdown-menu {
  background: #323a49;
  box-shadow: 3.22222px 3.22222px 5.63889px rgba(0, 0, 0, 0.2);
  color: #fff;
  border-radius: 25px 0px 25px 25px;
  padding: 12px 13px;
  margin-right: 20px;
  overflow: hidden;

}

.notification-dropdown-wrapper .dropdown-menu {
  width: 460px;
}

.header-right .dropdown-menu.show {
  transform: translate(0px, 57px) !important;
}

.header-right .dropdown-menu h2 {
  background: #141a26;
  padding: 11px 5px;
  font-size: 17px;
  text-align: center;
  margin: -14px -15px 0px;
  overflow: hidden;
}

.header-right .dropdown-menu a {
  color: #e1e1e1;
  font-size: 14px;
  text-align: center;
  border-bottom: 0.966667px solid #6e7e9c;
  line-height: 32px;
}

.header-right .dropdown-menu a:last-child {
  border: none;
}

.header-right .dropdown-menu a:hover {
  background: #e81d63;
  color: #ffff;
}

.profile-outer {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  overflow: hidden;
}

.profile-outer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-left .nav-item {
  background: #141a26;
  border: 1px solid #e7eaf1;
  border-radius: 8px;
  min-width: 196px;
  padding: 11px 40px 11px 18px;
  color: rgb(255 255 255 / 60%);
  font-size: 14px;
  background-image: url(../images/down-arrow.svg);
  background-repeat: no-repeat;
  background-position: right 7px top 20px;
}

.header-left .nav-item img {
  width: 26px;
  margin-right: 12px;
}

.header-left .dropdown-toggle::after {
  display: none;
}

.header-left .dropdown-menu {
  background: #323a49;
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.2);
  border-radius: 0px 25px 25px 15px;
  /* transform: translate(26px, 19px); */
  margin-left: -18px;
  margin-top: 10px;
  min-width: 516px;
  padding: 13px 13px;
}

.header-left .dropdown-menu a {
  color: #e1e1e1;
  font-size: 18px;
  border-bottom: 1.2px solid #6e7e9c;
  padding: 20px 32px;
  position: relative;
}

.header-left .dropdown-menu a.active::after {
  content: "";
  position: absolute;
  right: 0;
  width: 22px;
  height: 22px;
  background: #06bd53;
  border-radius: 100%;
  top: 50%;
  transform: translateY(-50%);
  right: 9%;
}

.header-left .dropdown-menu a:last-child {
  border: none;
}

.header-left .dropdown-menu a.active {
  color: #06bd53;
  background-color: transparent;
}

.header-left .dropdown-menu a:hover {
  background: transparent;
  color: #06bd53;
}

/* top-header */

/* feed-sidebar */
.feedsidebar {
  background: #141a26;
  width: 487px;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 59px 41px;
  overflow-y: auto;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transform: translateX(100%);
}

.feed-title h3 {
  font-size: 24px;
}

.feed-title h3 {
  font-size: 24px;
}

.post-card {
  background: #3c3f4a;
  border-radius: 16px;
  padding: 13px 31px;
}

.lg-circle {
  background: #141a26;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  overflow: hidden;
}

.post-figure {
  display: flex;
  align-items: center;
}

.post-figure h4 {
  font-size: 14px;
  color: #e1e1e1;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0px;
}

.post-figure .figcaption {
  padding-left: 20px;
}

.post-figure span {
  font-size: 12px;
  color: #e1e1e1;
  font-weight: 300;
}

.feed-card p {
  font-size: 14px;
  line-height: 23px;
  margin-bottom: 0px;
  color: #e1e1e1;
  font-weight: 300;
}

.feed-card .accordion-item {
  background: transparent;
  border: none;
}

.feed-card .accordion-button {
  background: transparent;
}

.feed-card .accordion-button:focus {
  box-shadow: none;
  outline: none;
}

.feed-card .like-comment {
  position: absolute;
  top: 17px;
}

.feed-card .accordion-button {
  width: auto;
  margin-left: auto;
  border-bottom: none;
  position: relative;
  top: 6px;
  right: 35px;
}

.feed-card .like-comment ul li {
  display: inline-block;
  font-size: 14px;
  color: #06bd53;
  margin-right: 28px;
  cursor: pointer;
}

.feed-card .like-comment ul li img {
  margin-right: 7px;
  position: relative;
  max-width: 18px;
}

.feed-card .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.feed-card .accordion-body {
  border-top: 1px solid #e2e0e04a;
}

.feed-card .accordion-header {
  margin-bottom: 9px;
}

.post-editor input[type="text"] {
  background: #505463;
  border-radius: 29px;
  border: none;
  padding: 8px 30px;
  color: #fff;
  width: calc(100% - 50px);
  margin-left: 17px;
}

.post-editor input[type="text"]::placeholder {
  font-size: 12px;
  color: #9ca3bb;
  font-weight: 300;
}

.post-editor input[type="text"]:focus {
  box-shadow: none;
}

.small-circle {
  width: 33px;
  height: 33px;
  background-color: #000;
  border-radius: 100%;
  overflow: hidden;
  margin-bottom: 0px;
}

.post-editor figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.comment-sec ul li figure {
  margin-bottom: 0px;
}

.comment-sec ul li h4 {
  font-size: 12px;
  color: #e1e1e1;
  margin-bottom: 0px;
  font-weight: 500;
}

.post-editor.d-flex.align-items-center {
  margin-bottom: 40px;
}

.comment-sec ul li .figcaption {
  background: #525665;
  border-radius: 7px;
  padding: 8px 12px;
}

.comment-sec ul li figcaption {
  margin-left: 14px;
  width: calc(100% - 52px);
}

.middle-circle {
  width: 36px;
  height: 36px;
  background: #000;
  border-radius: 100%;
}

.middle-circle img,
.small-circle img,
.lg-circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

.comment-sec li {
  margin-bottom: 28px;
}

.like-comment-post span {
  color: #06bd53;
  font-size: 12px;
  margin-right: 29px;
  cursor: pointer;
}

.like-comment-post {
  margin-top: 7px;
}

.cross-icon {
  display: block;
  position: absolute;
  top: 17px;
  right: 21px;
}

.feedsidebar-chat .common-tab {
  right: 180px;
  bottom: 0;
  position: fixed;
  max-width: 300px;
  width: 100%;
  min-height: 40vh;
  z-index: 99 !important;
}

.common-tab {
  background: #1b263b;
  border-radius: 15px 15px 0px 0px;
  overflow: hidden;
}

.common-tab .nav.nav-tabs {
  border: none;
}

.common-tab .nav.nav-tabs .nav-item {
  color: #707d9b;
  font-size: 10px;
  width: 50%;
  margin-bottom: 0px;
}

.common-tab .nav.nav-tabs .nav-item button {
  width: 100%;
  color: #707d9b;
  background: #253046;
  border: none;
  border-radius: 0px;
  margin: 0;
}

.common-tab .nav.nav-tabs .nav-item .nav-link.active {
  background: #06bd53;
  color: #000;
  font-weight: 1000;
  font-size: 11px;
}

.feedsidebar-chat ul li a {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #e1e1e1;
  text-decoration: none;
  font-weight: 600;
}

.feedsidebar-chat ul li a:hover {
  color: #06bd53;
}

.feedsidebar-chat .figure {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 13px;
}

.feedsidebar-chat .figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.feedsidebar-chat ul li {
  margin-bottom: 25px;
}

.common-tab .tab-content {
  padding: 21px 26px;
}

.message-btn {
  position: fixed;
  right: 10px;
  bottom: 10px;
  background: transparent;
  border: none;
  padding: 0px;
}

.message-btn:hover {
  background: transparent;
}

.feedsidebar-chat ul li .theme-btn {
  border-radius: 18px;
  padding: 5px 16px;
  font-size: 11px;
  font-weight: 500;
}

.common-input input[type="text"] {
  background: #253047;
  border-radius: 29px;
  border: none;
  padding: 6px 14px 6px 43px;
  color: #fff;
  width: calc(100% - 50px);
  margin-left: 17px;
}

.common-input .search {
  position: absolute;
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
}

.common-input input[type="text"]::placeholder {
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
}

/* feed-sidebar */
.feedsidebar-chat {
  right: 0;
  left: 0;
  bottom: 0;
}

/* feed-sidebar */

/* ======================created-by-RR========================= */
.date_slide {
  background: #141a26;
  border: 1px solid #e7eaf1;
  border-radius: 12px;
  padding: 13px 62px;
  margin: 24px 0;
}

.date_slide ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  color: #fff;
  font-size: 14px;
  margin: 0;
}

.days_fix {
  padding: 0 10px;
  text-align: center;
  font-weight: 300;
  border-radius: 10px;
  transition: all 0.5s;
}

.days_fix_active {
  color: #06bd53;
}

.days_fix:hover {
  /* background-color: #06bd53; */
  color: #06bd53;
  border-radius: 10px;
  cursor: pointer;
}

.days_fix strong {
  margin-left: 8px;
}

.main_content_part {
  padding: 20px 0 0px 0;
}

.league_list {
  background: #222731;
  border-radius: 18px;
  padding: 34px 45px 20px 45px;
  text-align: center;
  position: relative;
  margin-bottom: 26px;
  margin-right: 8px;
}

.league_detail_row {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.brand_cols {
  font-family: "futuramedium";
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  flex-grow: 1;
  cursor: pointer;
  flex: 0 0 28%;
  max-width: 28%;
  padding: 0px 16px;
}

.league_center_cols .small_text {
  font-size: 12px;
  font-weight: 300;
}

.league_center_cols {
  padding: 0 15px;
  flex: 0 0 44%;
  max-width: 44%;
}

.text-light-blue {
  color: #707d9b;
}

.league_center_cols h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.goals-count {
  padding: 24px 0 28px 0;
  line-height: 1.2;
}

.goals-count span {
  font-size: 32px;
  padding: 4px;
  margin: 0 13px;
  font-weight: 600;
  width: 49px;
  height: 49px;
  display: inline-block;
  text-align: center;
  line-height: 38px;
  border-radius: 10px;
  vertical-align: middle;
}

.goals-count span.border-1 {
  border: 1px solid #929292;
}

.brand_cols figure {
  background-color: #4c618a;
  width: 92px;
  height: 92px;
  border-radius: 100px;
  display: inline-flex;
  padding: 5px;
  overflow: hidden;
}

.brand_cols strong {
  display: block;
  font-family: "futura-500";
  letter-spacing: 1px;
}

.finished_tag {
  position: absolute;
  top: 24px;
  left: 44px;
  background: #141a26;
  padding: 5px 20px;
  font-size: 12px;
  border-radius: 8px;
  font-family: "futura-500-italic";
  text-transform: uppercase;
}

.right_tag {
  position: absolute;
  top: 24px;
  right: 44px;
  padding: 5px 20px;
  font-size: 12px;
  border-radius: 8px;
  font-family: "futurabold";
  text-transform: uppercase;
}

.pink_bg {
  background: #e81d63;
}

.bg-green {
  background-color: #06bd53;
}

.comingsoon_time ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
}

.comingsoon_time ul li {
  padding: 0 20px;
}

.comingsoon_time ul li .days {
  font-size: 22px;
}

.comingsoon_time ul li .timerefdays {
  font-size: 12px;
  color: #707d9b;
  text-transform: capitalize;
  font-weight: 300;
}

.league_detail_row .theme-btn {
  font-size: 12px;
  padding: 12px 50px;
  cursor: pointer;
}

.down_arrow {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 15px;
}

.histric_result_row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.result_value_inner {
  border: 1px solid #929292;
  padding: 10px 20px;
  border-radius: 10px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  min-width: 315px;
  min-height: 70px;
  align-items: center;
}

.result_value_inner li strong {
  display: block;
  font-size: 24px;
  font-family: "futura-500";
  line-height: 1;
}

.result_value_cols {
  padding: 0 24px;
}

.result_value_inner li span {
  font-size: 12px;
  color: #e1e1e1;
  font-weight: 300;
  font-family: "Rubik", sans-serif;
  letter-spacing: 1px;
}

.result_value_cols h4 {
  font-size: 14px;
  font-weight: 400;
  padding: 5px 0;
  color: white;
}

.league_readmore {
  padding: 15px;
}

.league_comment_section .figcaption h4 {
  font-size: 14px;
  font-weight: 500;
}

.league_comment_section .figcaption p {
  font-size: 14px;
  font-weight: 300;
  color: #fff;
}

.league_comment_section .form-control {
  background: #505463;
  border-radius: 29px;
  border: none;
  padding: 12px 20px;
  color: #fff;
  width: calc(100% - 50px);
  margin-left: 17px;
  height: 44px;
  font-size: 14px;
  overflow-y: hidden;
}

.league_comment_textarea {
  padding-left: 20px;
  margin-right: 50px;
}

.right_tag.right_tag {
  font-family: "Rubik";
  cursor: pointer;
}

.league_comment_list {
  padding: 36px 0 0 0;
  color: white
}

.league_comment_section li {
  margin-bottom: 15px;
}

.league_comment_section>ul {
  max-height: 334px;
  overflow-y: auto;
  padding-right: 36px;
}

.league_comment_section>ul .like-comment-post img {
  vertical-align: middle;
  margin-right: 5px;
}

.friends_predictions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
}

.predication_tag {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 13px;
  flex: 0 0 32px;
  cursor: pointer;
}

.predication_tag_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.predication_value .right_tag.pink_bg {
  position: initial;
  margin-left: 15px;
  font-size: 14px;
  font-weight: 500;
  padding: 3px 15px;
}

.prediction_wrapper {
  padding: 0px 0 0px 10px;
}

.prediction_wrapper {
  padding: 0px 0 0px 10px;
}

.team_campare_value figure {
  width: 82px;
  height: 82px;
}

.team_campare_value {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 340px;
  margin: 0 auto;
  text-align: center;
  padding: 24px 0;
  margin-bottom: -40px;
}

.team_goals_value {
  padding: 0 10px;
}

.team_goals_value span {
  font-size: 32px;
  font-weight: 600;
  display: inline-block;
  padding: 4px;
  width: 42px;
  height: 42px;
  line-height: 1;
}

.team_cols_list {
  padding: 20px 30px;
  background: #222731;
  border-radius: 18px;
  margin-bottom: 15px;
  margin-right: 10px;
}

.team_compare_value {
  background: #141a26;
  padding: 10px;
  border-radius: 15px;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  min-width: 80px;
  height: 72px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.team_compare_value span {
  display: inline-block;
  padding: 0 8px;
}

.team_compare_value_tag {
  padding: 20px 32px;
  border-radius: 15px;
  min-width: 105px;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.goals_list_logo .predication_value h6 {
  font-family: "Rubik";
  font-weight: 600;
  font-size: 18px;
}

.team_cols_list:last-child {
  margin-bottom: 0;
}

.create-compet {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  padding: 64px 0px;
}

.create-compet h3 {
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 20px;
}

.create-compet p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #e1e1e1;
  margin-bottom: 39px;
}

.create-compet figure {
  margin-bottom: 35px;
}

.create-compet a.theme-btn {
  text-decoration: none;
  width: 100%;
  display: block;
  font-weight: 600;
}

.common-modal .modal-header {
  background: #253046;
  border: none;
  flex-direction: column;
}

.common-modal .modal-header .btn-close {
  position: absolute;
  top: 19px;
  right: 26px;
  background-image: url(../images/cross-icon.svg);
  opacity: 1;
}

.common-modal .modal-header .modal-title img {
  margin-right: 11px;
}

.common-modal .modal-header .modal-title {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #707d9b;
  display: flex;
  align-items: center;
}

.common-modal .modal-content {
  border-radius: 24px;
  overflow: hidden;
  background: transparent;
}

.common-modal .modal-body {
  padding: 40px;
  background: #141a26;
}

.common-modal .modal-body .join-text {
  text-align: center;
  display: block;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #e1e1e1;
  margin-bottom: 24px;
}

.common-modal .modal-body .form-control {
  background: #141a26;
  border: 1px solid #929292;
  border-radius: 14px;
  margin-bottom: 14px;
  height: 50px;
  padding: 9px 23px;
  color: #fff;
}

.common-modal .modal-dialog {
  max-width: 618px;
}

.common-modal .modal-body .form-control::placeholder {
  font-weight: 300;
  font-style: italic;
  color: #929292;
  font-size: 14px;
}

.common-modal .modal-body .prize-btn {
  background: transparent;
  border: none;
  text-align: center;
  display: block;
  justify-content: center;
  width: 100%;
  color: #06bd53;
}

.common-modal .modal-body .prize-btn:hover {
  background-color: transparent !important;
  border: none;
  color: #e81d63 !important;
}

.common-modal .modal-footer {
  background: #141a26;
  border: none;
  justify-content: center;
  padding: 30px 40px;
}

.common-modal .modal-footer button {
  width: 100%;
  font-size: 12px;
  font-weight: 700;
}

.competetion_slider .item-sl1 figure {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  background: #4c618a;
  margin: 0 auto 14px;
  border: 2px solid transparent;
}

.competetion_slider .item-sl1 figure img {
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.competetion_slider .item-sl1 h4 {
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #e1e1e1;
}

.competetion_slider {
  padding: 16px 39px 4px 39px;
  border: 1px solid #929292;
  border-radius: 14px;
}

.inner-content {
  margin-top: 43px;
}

h3.select-title {
  display: block;
  text-align: center;
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #e1e1e1;
  font-weight: 500;
}

.competetion_slider .item-sl1.active figure {
  border: 2px solid #06bd53;
}

.competetion_slider .item-sl1.active h4 {
  color: #06bd53;
}

.competetion_slider .item-sl1 {
  cursor: pointer;
}

.slick-prev:before,
.slick-next:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: transparent !important;
  content: "";
  background-repeat: no-repeat;
}

.slick-prev:before {
  left: 0;
  background-image: url(../images/left-arrow.svg);
}

.slick-next:before {
  right: 0;
  background-image: url(../images/right-arrow.svg);
}

.modal-body-heading h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 20px;
}

.modal-body-heading p {
  color: #e1e1e1;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
}

.common-modal .color-header {
  background: #141a26;
  padding: 21px 20px;
}

.friend-search-input input {
  padding: 20px 50px !important;
}

.friend-search-input .search {
  left: 22px;
}

.common-modal .modal-body .common-tab {
  background: #141a26;
}

.theme-btn.tranparent-btn {
  background: transparent;
  color: #06bd53;
}

.theme-btn.tranparent-btn:hover img {
  filter: brightness(0) invert(1);
}

.common-modal .common-tab .tab-content {
  padding: 40px 40px 16px;
}

.common-modal .common-tab .nav.nav-tabs .nav-item {
  font-size: 12px;
}

.common-modal .common-tab .nav.nav-tabs .nav-item button {
  padding: 16px 18px;
  margin: 0;
}

.friend-suggestion .friend-list span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #e1e1e1;
  padding-left: 12px;
}

.friend-suggestion .friend-list span:hover {
  color: #06bd53;
}

.friend-suggestion .small-circle {
  width: 36px;
  height: 36px;
}

.friend-suggestion .small-circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.friend-suggestion figcaption {
  width: calc(100% - 36px);
}

.friend-suggestion .form-check-inline {
  width: 100%;
  padding-left: 0px;
}

.friend-suggestion .form-check-label {
  width: 100%;
}

.friend-suggestion .form-check-input[type="checkbox"] {
  background: transparent;
  width: 26px;
  position: absolute;
  right: 48px;
  border: none;
  top: 50%;
  transform: translateY(-50%);
}

.friend-suggestion .friend-list {
  cursor: pointer;
}

.friend-suggestion .form-check-input:focus {
  box-shadow: none;
  border: none;
}

.friend-suggestion .form-check-input[type="checkbox"]:checked {
  background-image: url(../images/check-arrow.svg);
  background-repeat: no-repeat;
}

.friend-suggestion .form-check-input:checked {
  border: none;
}

.friend-suggestion {
  background: #1e2637;
  border-radius: 14px;
  padding: 27px 27px 7px;
}

.friend-suggestion ul {
  overflow-y: auto;
  height: 218px;
  overflow-x: hidden;
}

.friend-suggestion ul li {
  margin-bottom: 22px;
  position: relative;
}

.feedsidebar::-webkit-scrollbar {
  width: 4px;
}

/* scollbar */

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 42px;
  background: #3a4968;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #586081;
  border-radius: 42px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #586081;
}

/* scollbar */
.modal-parellal-heading img {
  margin-right: 12px;
  position: relative;
  top: -2px;
}

.common-modal .common-tab .nav.nav-tabs .nav-link.active img {
  filter: brightness(0);
}

.common-modal .tab-content {
  position: relative;
}

.inner-close-btn {
  background: transparent !important;
  position: absolute;
  top: 3px;
  right: 7px;
}

.listing-heading h4 {
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: baseline;
}

.listing-heading img {
  margin-right: 9px;
}

.listing-heading .theme-btn {
  min-width: 238px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}

a.theme-btn:hover {
  color: #fff;
}

.competion-inner-listing li {
  background: #222731;
  border-radius: 18px;
  padding: 49px 25px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  margin-right: 8px;
}

.competion-inner-listing li .competion-user {
  display: flex;
  align-items: center;
  padding-right: 15px;
  flex: 0 0 68%;
  max-width: 68%;
}

.competion-inner-listing li .competion-user-flex {
  display: flex;
  align-items: center;
  flex: 0 0 60%;
  max-width: 60%;
}

.middle-user figure {
  margin: 0 auto;
}

.competion-inner-listing li .competion-user-flex figcaption {
  padding: 0px 16px;
  width: calc(100% - 60px);
}

.competion-inner-listing li .competion-user-flex h5 {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
}

.competion-inner-listing li .competion-user-flex figcaption span {
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.competion-inner-listing li .competion-user .inning-stage span {
  background: #141a26;
  border-radius: 8px;
  padding: 5px 16px;
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: block;
  text-align: center;
  margin-bottom: 10px;
}

.middle-user figure {
  max-width: 34px;
}

.middle-user {
  flex: 0 0 auto;
}

.inning-stage {
  flex: 1 1;
  max-width: 100%;
  flex-wrap: wrap;
}

.competetion-right-panel {
  display: flex;
  align-items: center;
  flex: 0 0 32%;
  max-width: 32%;
  justify-content: right;
}

.competetion-right-panel .gitt-card {
  /* max-width:20px; */
  color: #fff;
  text-decoration: none;
  /* flex:0 0 20px; */
  flex-grow: 1;
  text-align: right;
}

.competetion-right-panel .theme-btn {
  margin: 0px 20px;
  min-width: 148px;
  padding: 8px;
  font-size: 12px;
  font-weight: 600;
}

.competetion-right-panel .more-detail {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #06bd53;
  text-decoration: none;
  min-width: 110px;
}

.competetion-right-panel .more-detail img {
  margin-left: 10px;
}

.competion-inner-listing li .competion-user-flex figure {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  overflow: hidden;
}

.date_global-slider .nav-tabs {
  background: #141a26;
  border: 1px solid #e7eaf1;
  border-radius: 12px;
  padding: 7px 24px;
  margin: 24px 0;
  justify-content: space-between;
}

.date_global-slider .nav-tabs .nav-link {
  border: none;
  background: transparent;
  font-size: 14px;
  color: #e1e1e1;
}

.date_global-slider .nav-tabs .nav-link:hover {
  color: #06bd53;
}

.global-team-outer .predication_tag {
  width: 60px;
  height: 60px;
  flex: 0 0 60px;
}

.date_global-slider .nav-tabs .nav-link.active {
  color: #06bd53;
}

.theme-btn.delete-btn {
  background: #d11b1b;
  border-color: #d11b1b;
}

.owner-text {
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.find-competetion-list .competetion-right-panel {
  flex-grow: 1;
}

.modal-body-heading span {
  font-weight: 500;
  font-size: 14px;
  color: #e81d63;
  font-style: italic;
  letter-spacing: 1px;
}

.date_slide .slick-prev:before,
.date_slide .slick-next:before {
  transform: translateY(-25%);
}

.feed-title .theme-btn {
  font-size: 12px;
  font-weight: 600;
  padding: 7px 18px;
}

/* created-by-RR */

.green_droap_down .btn {
  background: #06bd53;
  color: #000;
  font-size: 12px;
  font-weight: 600;
  padding: 12px 62px;
  border-radius: 8px;
}

.green_droap_down .btn:hover {
  background: #06bd53;
  color: #000;
}

.green_droap_down .dropdown-toggle::after {
  position: absolute;
  top: 50%;
  transform: translate(-50%);
  right: 20px;
}

.green_droap_down .dropdown-menu a {
  font-size: 13px;
  color: #fff;
}

.green_droap_down .dropdown-menu {
  width: 100%;
  background-color: #222731;
}

.green_droap_down .dropdown-menu a:hover {
  background-color: #2b313c;
}

.text-green {
  color: #06bd53;
}

.team-cols-head li {
  padding: 0 10px;
  min-width: 80px;
  text-align: center;
  margin: 0 10px;
  color: #929292;
  font-weight: 600;
  font-size: 18px;
}

.standing_list-main {
  margin: 20px 0;
  transition: 0.9s;
}

.standing_value li a {
  display: inline-flex;
  text-decoration: none;
  background: #141a26;
  margin: 0 10px;
  min-width: 80px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  height: 64px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.standing_value li a:hover {
  background-color: #e81d63;
}

.ml-64 {
  margin-left: 64px;
  color: #929292;
}

.standing_list_left .predication_tag {
  width: 60px;
  height: 60px;
  flex: 0 0 60px;
}

/**===new=RR====**/

.sidebar_toggle {
  background: #20293b;
  position: fixed;
  top: 50%;
  right: -15px;
  transform: translateY(-50%);
  padding: 15px;
  width: 70px;
  height: 70px;
  border-radius: 100px 0 0 100px;
  border: none;
}

.sidebar_toggle img {
  transform: rotate(90deg);
}

.right-sidebar-toggle .feedsidebar {
  transform: translate(0);
}

.right-sidebar-toggle .main-dashboard .main {
  width: calc(100% - 791px);
  padding-right: 24px;
  margin-left: 304px;
}

.left_sidebar_toggle {
  background: #141a26;
  padding: 6px 10px;
  border-radius: 8px;
  border: 1px solid #e7eaf1;
  margin-right: 15px;
  height: 50px;
  width: 54px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}

.left_sidebar_toggle:hover {
  background: #e81d63;
}

.left_sidebar_toggle img {
  max-height: 24px;
}

.comment-sec li ul {
  list-style: none;
}



.standing-data_list_main {
  overflow-x: auto;
}

.standing-data_list_main .team_cols_list {
  min-width: 920px;
}

.standing_list_left .predication_value {
  padding-right: 15px;
}

/* subscription */

/*--------------subcription--------*/

.priceTag {
  position: relative;
}

.days::before {
  position: absolute;
  top: -12px;
  left: 0px;
  content: "";
  width: 0;
  height: 0;
  border-bottom: 12px solid var(--primaryClr);
  border-left: 16px solid transparent;
  z-index: -1;
}

.pricingList:after {
  position: absolute;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  height: 575px;
  padding: 20px;
  content: "";
  z-index: 0;
  top: 54px;
}

.priceTag span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 14px;
  font-weight: 700;
  color: #000;
  padding: 10px 28px;
  margin-left: -25px;
  position: relative;
  z-index: 1;
}

.block>.priceTag .days {
  background: #eb1d63;
}

.block.premimum-sec>.priceTag .days {
  background: #06c053;
}

.block .days::before {
  border-bottom: 12px solid #a01f53;
  border-left: 16px solid #73602700;
}

.block.premimum-sec .days::before {
  border-bottom-color: #087f38;
  border-left: 16px solid transparent;
}

.subscription-inner-sec.premimum-sec h5 {
  color: #06c053;
}

.subscription-inner-sec.premimum-sec a {
  background: #06bd53;
  color: #000;
}

.block.gold-sec .days::before {
  border-bottom-color: #736027;
  border-left: 16px solid transparent;
}

.block.gold-sec>.priceTag .days {
  background: linear-gradient(90deg,
      #eab857 0.92%,
      #e6a64d 5.9%,
      #f6c37e 12.64%,
      #ffe891 65.33%,
      #f2a72b 100.59%);
}

.subscription-inner-sec.gold-sec h5 {
  background: linear-gradient(90deg,
      #eab857 0.92%,
      #e6a64d 5.9%,
      #f6c37e 12.64%,
      #ffe891 65.33%,
      #f2a72b 100.59%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.subscription-inner-sec.gold-sec a {
  background: linear-gradient(90deg,
      #eab857 0.92%,
      #e6a64d 5.9%,
      #f6c37e 12.64%,
      #ffe891 65.33%,
      #f2a72b 100.59%);
  color: #000;
}

/* subscription */

/* subscription-inner */

.subscription-inner-sec {
  background: #222731;
  border-radius: 20px;
  padding: 38px 10px 9px;
  width: 100%;
}

.subscription-inner-sec h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #eb1d63;
  text-align: center;
  margin: 17px 0px;
  font-family: "futura-500";
}

.subscription-inner-sec ul li {
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #eeeeee;
  border-bottom: 1px solid #eeeeee4d;
  padding: 14px 0px;
  text-align: left;
}

.subscription-inner-sec a {
  text-decoration: none;
  text-align: center;
  display: block;
  background: #2c3442;
  border-radius: 8px;
  padding: 10px 10px;
  margin-top: 41px;
  font-size: 12px;
  font-weight: 600;
  color: #929292;
  cursor: pointer;

}


.subscription-content {
  padding: 20px 23px;
}

.subscription-content a:hover {
  color: #0905f1 !important;
}

.subscription-sec {
  padding: 45px 0px;
}

.common-user-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.common-user-head figure {
  width: 52px;
  height: 52px;
  margin-bottom: 0px;
  border-radius: 100%;
}

.common-user-head figcaption {
  width: calc(100% - 52px);
  padding-left: 21px;
}

.common-user-head figcaption h4 {
  margin: 0;
  font-weight: 600;
  font-size: 22px;
  line-height: 20px;
  color: #e1e1e1;
}

.common-user-head .premimum h4 {
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #e1e1e1;
  margin: 0;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.common-user-head .premimum {
  flex: 1;
  max-width: 100%;
  text-align: center;
  padding: 0px 14px;
}

.common-user-head .d-flex {
  flex: 0 0 50%;
  max-width: 50%;
  border-right: 1px solid #929292;
  padding-right: 14px;
}

.common-inner-sec {
  padding: 17px 0px 17px 21px;
}

.profile-cirlce {
  background: #222731;
  border-radius: 18px;
  padding: 25px;
  text-align: center;
  width: 100%;
}

.profile-cirlce h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-top: 20px;
}

/* profile-dashboard */

.profile-cirlce .percent {
  position: relative;
}

.profile-cirlce svg {
  position: relative;
  width: 122px;
  height: 126px;
  transform: rotate(-90deg);
}

.profile-cirlce svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #3d3d3d;
  stroke-width: 10;
  stroke-linecap: round;
}

.profile-cirlce svg circle:last-of-type {
  stroke-dasharray: 625px;
  stroke-dashoffset: calc(625px - (625px * var(--percent)) / 100);
  stroke: #3498db;
}

.profile-cirlce .number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.profile-cirlce .number h3 {
  font-size: 24px;
  line-height: 42px;
  margin-bottom: 0px;
  font-family: "futura-500";
}

.profile-cirlce:nth-child(1) svg circle:last-of-type {
  stroke: #06bd53;
}

.profile-cirlce:nth-child(2) svg circle:last-of-type {
  stroke: #2ecc71;
}

/* profile-dashboard */

/* infor-profile */

.profile-form .form-label {
  font-style: italic;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  text-align: right;
  flex: 0 0 156px;
  max-width: 156px;
  color: #8a8a8a;
  padding-right: 31px;
}

.profile-form .form-control {
  border: 0.7px solid #929292;
  border-radius: 14px;
  background: transparent;
  padding: 18px 30px;
  flex: 1;
  max-width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #fff;
}

.profile-form .form-group {
  display: flex;
  align-items: center;
}

.profile-form {
  margin-top: 48px;
}

.competion-inner-listing li .competion-user-flex figcaption span.global-ranking {
  font-weight: 300;
}

.profile-cirlce .blue-fill svg circle {
  fill: #06bd53;
  stroke: #06bd53;
}

.profile-cirlce .light-fill svg circle {
  fill: #3d3d3d;
  stroke: #3d3d3d;
}

.profile-cirlce .light-fill svg circle:last-of-type {
  stroke: #3d3d3d;
}

.listing-heading .theme-btn img {
  filter: brightness(0);
}

.listing-heading .theme-btn:hover img {
  filter: brightness(0) invert(1);
}

.profile-form .form-control1 {
  padding: 18px 30px;
  max-width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #fff;
}

.post-image {
  padding: 20px 0px;
}

.post-image a {
  background: #505463;
  border-radius: 16px;
  overflow: hidden;
  display: block;
  max-height: 191px;
}

.feed-card .accordion-button::after,
.feed-card .accordion-button:not(.collapsed)::after {
  filter: grayscale(500%) brightness(165%) sepia(355%) hue-rotate(50deg) saturate(532%) !important;
}

.header-right ul li a:hover {
  color: #e81d63;
}

.competetion-right-panel .more-detail:hover {
  color: #fff;
  cursor: pointer;
}

.competetion-right-panel .more-detail:hover img {
  filter: brightness(0) invert(1);
}

.feed-card .like-comment ul li:hover,
.like-comment-post span:hover {
  color: #fff;
}

.feed-card .like-comment ul li:hover img {
  filter: brightness(0) invert(1);
}

/* .sidebar-menu li a:hover {
  box-shadow: 9px 0px 36px 29px #ffffff24;
} */

.green_droap_down .btn:hover {
  background: #e81d63;
  color: #fff;
  border-color: #e81d63;
}

.green_droap_down .btn:hover img,
.upload_icon:hover img {
  filter: brightness(0) invert(1);
}

.gift-price-listing {
  position: absolute;
  background: #323a49;
  padding: 19px 15px 11px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  border-radius: 25px 0px 25px 25px;
  right: 14px;
  min-width: 118px;
}

.gift-price-listing li {
  padding: 0px;
  background: transparent;
  margin-bottom: 6px;
}

.gift-price-listing li span {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #06bd53;
  padding-left: 9px;
}

/* infor-profile */

/* subscription-inner */

/* popup sm */
.pick_header h3 {
  font-size: 18px;
  font-weight: 100;
  line-height: 18px;
  color: #bababa;
  margin-bottom: 0;
  /* font-family: "futura-500"; */
  font-family: "system-ui";
}

.pick_header span {
  font-weight: 500;
}

.pick_modal .brand_cols i {
  color: #bdbdbd;
  font-size: 14px;
}

.l_bg,
.w_bg,
.d_bg {
  min-height: 28px;
  min-width: 29px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-family: "Rubik", sans-serif;
  padding: 0px 7px;
}

.l_bg {
  background: #e81d63;
}

.w_bg {
  background: #06bd53;
}

.d_bg {
  background: #828282;
}

.pic_upload {
  height: 227px;
  width: 529px;
  border-radius: 16px;
  background: #222e47;
  position: relative;
}

.pic_img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.pic_img input {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
  z-index: 9;
}

.pic_img span div {
  border: 2px solid #fff;
}

.pic_upload h5 {
  font-family: "futura-500";
  font-size: 14px;
  margin-bottom: 0;
}

.pic_upload i {
  font-weight: 300;
  font-size: 12px;
  color: #9ca3bb;
}

.winner_info li img {
  width: 50px;
  height: 50px;
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.pic_img span {
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0;
  bottom: 0;
  max-width: 160px;
  margin: 0 auto;
  transform: translateY(-50%);
  height: max-content;
  display: flex;
  align-items: center;
  z-index: 3;
}

.winner_info h3 {
  font-size: 12px;
  color: #e1e1e1;
  font-weight: 300;
  font-family: "Rubik", sans-serif;
  letter-spacing: 1px;
}

.cPassword_modal .modal-body .form-control {
  background: #242f44;
  border: 0;
  padding: 30px 15px;
}

.logout_modal h5 {
  font-weight: 300;
  font-size: 14px;
  color: #e1e1e1;
  margin-bottom: 0;
}

.logout_modal .modal-body {
  padding: 30px 10px;
}

.reqFriend_modal .modal-header {
  background: #141a26;
}

.reqFriend_modal h3 {
  font-family: "futura-500";
  font-size: 18px;
}

.reqFriend_modal h3 i {
  font-family: "futuralight_italic";
}

.reqFriend_modal h4 {
  font-weight: 300;
  font-size: 14px;
  color: #bdbdbd;
  font-family: "futuralight_italic";
}

.reqFriend_modal h4 span {
  color: #fff;
}

.reqFriend_modal .pink_bg {
  font-weight: 500;
  font-size: 14px;
  color: #000;
  padding: 5px 15px;
  border-radius: 8px;
}

.reqFriend_modal .friend_img {
  width: 74px;
  height: 74px;
  border-radius: 100%;
  margin: 0 auto 20px;
}

.not_active {
  width: 14px;
  height: 14px;
  background: #d11b1b;
  border-radius: 100%;
  display: block;
  float: right;
}

.logout_main p {
  width: calc(100% - 50px);
  margin-bottom: 28px;
}

.logout_main .action_notify {
  width: 50px;
  cursor: pointer;
}

.logout_main {
  border-bottom: 1px solid #828282;
}

.logout_main:last-child {
  border: 0;
  padding-bottom: 0 !important;
}

.alert_modal .modal-body {
  padding: 42px 0;
}

.subscribe_head h4 {
  color: #e81d63;
  font-size: 18px;
  font-family: "futuralight_italic";
}

.form-check-input:checked {
  background-color: transparent;
  border-color: #929292;
}

.custom_check {
  display: inline-block;

  cursor: pointer;
  line-height: 22px;
  position: relative;
  padding-left: 30px;
}

.custom_check input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  margin: 0px 0px;
  z-index: 2;
}

.custom_check .check_indicator {
  height: 22px;
  width: 22px;
  position: absolute;
  top: 0px;
  left: 0px;
  background: transparent;
  border: 1px solid #929292;
  border-radius: 0px;
}

.custom_check input:checked+.check_indicator:before {
  width: 12px;
  height: 22px;
  position: absolute;
  left: 9px;
  top: -7px;
  border: solid #06bd53;
  border-width: 0px 2px 2px 0px;
  transform: rotate(45deg);
  content: "";
}

.subscribe_modal .custom_check a {
  color: #06bd53;
  text-decoration: none;
}

.subscribe_modal .custom_check {
  font-size: 14px;
  color: #e1e1e1;
}

.subscribe_modal p {
  font-size: 14px;
  line-height: 20px;
  color: #e1e1e1;
  font-family: "futura-500";
}

.pick_modal .modal-common-body i {
  font-size: 18px;
  font-family: "futura-500";
}

.winner_info li img {
  margin-bottom: 10px;
}

.theme-btn:disabled {
  background: #242f45;
  color: #000;
  border-color: #242f45;
}

.action_notify a:hover img {
  filter: invert(27%) sepia(87%) saturate(5589%) hue-rotate(328deg) brightness(94%) contrast(94%);
  -webkit-filter: invert(27%) sepia(87%) saturate(5589%) hue-rotate(328deg) brightness(94%) contrast(94%);
  -moz-filter: invert(27%) sepia(87%) saturate(5589%) hue-rotate(328deg) brightness(94%) contrast(94%);
}

/* popup sm */

.gift-price-listing li {
  padding: 0;
}

.profile-dashboard.row {
  padding: 107px 7px;
}

.common-upgrade-header {
  margin-bottom: 50px;
}

.subscription-content ul.list-unstyled {
  height: 354px;
  overflow-y: auto;
}

.my-profile_friends .competion-user {
  flex: 0 0 50%;
  max-width: 50%;
}

.rotate-arrow {
  transform: rotate(-180deg);
  transition: 0.4s;
}

a.right_tag.right_tag:hover {
  background: #e81d63;
  color: #fff !important;
}

.like-comment-post span:hover img {
  filter: brightness(0) invert(1);
}

.common-modal .modal-header .btn-close:focus {
  box-shadow: none;
}

.common-modal .modal-header .btn-close:hover,
.delete-icon-pre a:hover img,
.competetion-right-panel .gitt-card:hover img,
.gitt-card:hover img,
.cross-icon:hover img,
.logout_main .action_notify:hover img {
  filter: brightness(0) invert(1);
}

.feedsidebar-chat .common-tab .tab-content {
  max-height: 320px;
  overflow-y: auto;
}

.winner_info li h4 {
  font-family: "futura-500";
}

.sidebar_toggle:hover {
  background: #e81d63;
  color: #fff;
}

.sidebar_toggle:hover img {
  filter: brightness(0) invert(1);
}

.notification:hover img {
  filter: grayscale(500%) brightness(165%) sepia(355%) hue-rotate(50deg) saturate(532%) !important;
}

.header-left .dropdown-menu .inner-scoller {
  height: 412px;
  overflow-y: auto;
  padding: 0px 12px;
}





/* ================front-page-css=============== */


/* front-page */

.site_header .navbar-brand {
  width: 200px;
}

.site_header li {
  list-style: none;
}

.site_header .nav-link {
  background: #151515;
  color: #06bd53;
  border-radius: 16px;
  font-size: 14px;
  padding: 6px 10px;
}

.nav-link>img {
  margin: 0 5px;
  max-width: 19px;
}

.nav-link {
  margin-left: 10px;
}

.slide-section {
  padding: 5.5rem 0rem 3.5rem 0rem;
  background: #000;
  position: relative;
}

.scnd-section {
  background-color: #31ad57;
  padding: 4rem 0rem 9rem 0rem;
}

.scnd-section .hedding-small {
  font-family: Futura, sans-serif;
  font-weight: 300;
  color: #fff;
  font-size: 20px;
}

.scnd-section h1 {
  font-family: "Premier 2019", sans-serif;
  font-style: normal;
  font-size: 46px;
  font-weight: 500;
  color: #333;
}

.scnd-section h6 {
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #333;
}

.scnd-section p {
  font-family: "Rubik", sans-serif;
  font-size: 19px;
  font-weight: 400;
  color: #333;
}

.scnd-section .btn-white {
  background: #fff;
  padding: 12px 0 12px 0;
  text-align: center;
  display: block;
  border-radius: 10px;
  color: #000;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 16px;
  transition: 0.6s ease;
  cursor: pointer;
  border: 1px solid transparent;
  text-decoration: none;
}

.btn-app-store,
.btn-android-store {
  background: #000;
  padding: 8px 46px 13px 45px;
  border-radius: 10px;
}

.btn-android-store img,
.btn-app-store img {
  max-height: 18px;
}

.trd-section {
  background-color: #000000;
}

.trd-section .cont-desc {
  position: relative;
  top: -41px;
  text-align: center;
}

.crcl-d {
  background: #141a26;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  margin: auto;
  margin-bottom: 20px;
}

.crcl-d .crcl-1 {
  padding: 19px;
  margin-top: 7px;
}

.trd-section h3 {
  font-family: "Premier 2019", sans-serif;
  color: #fff;
  font-weight: 500;
}

.trd-section h5 {
  font-family: "Rubik", sans-serif;
  font-weight: 300;
  color: #fff;
}

.htp {
  color: #fff;
  font-weight: 300;
}

.htp span {
  font-weight: 600;
  color: #e81d63;
  font-family: "Premier 2019", sans-serif;
  font-weight: 500;
  font-size: 50px;
}

.crcl-d-2-0 {
  width: auto;
  padding: 20px;
  margin-bottom: 27px;
  text-align: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 200px;
  position: relative;
}

.crcl-d-2 {
  width: 130px;
  height: 130px;
  background: #141a26;
  border-radius: 50%;
  text-align: center;
  position: relative;
  left: 38px;
}

.crcl-d-2-0 .img-play-1 {
  position: absolute;
  bottom: 0;
  left: -15px;
  width: 69%;
  margin-bottom: -13px;
}

.signup-col h2 {
  font-family: "Premier 2019", sans-serif;
  color: #fff;
  font-weight: 500;
}

.signup-col p {
  font-family: "Rubik", sans-serif;
  font-weight: 300;
  color: #fff;
}

.signup-col .btn-signup {
  background: #06bd53;
  padding: 12px 0 12px 0;
  display: block;
  border-radius: 10px;
  color: #000;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 16px;
  transition: 0.6s ease;
  cursor: pointer;
}

.prediction-card {
  background-color: #0f0f0f;
  border-radius: 13px;
}

.prediction-card .pre-head {
  color: #e81d63;
  font-family: "Rubik", sans-serif !important;
}

.line-div:before {
  position: absolute;
  top: 26rem;
  content: "";
  width: 222px;
  height: 149px;
  background: url("../images/line-2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-left: 1.5rem;
}

.line-div:after {
  position: absolute;
  top: 9rem;
  content: "";
  width: 222px;
  height: 149px;
  background: url("../images/line-1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-left: 1.5rem;
}

.crcl-d-2-0 .img-play-1-2 {
  width: 70px;
  right: 100px;
  left: auto;
  top: -29px;
}

.background-account {
  background-image: url("../images/cols-bg.png");
}

.select-compition-bg {
  background-image: url("../images/normal-bg.png");
}

.crcl-d-2-3 {
  height: 122px;
}

.crcl-d-2-3 .crcl-ds-3 {
  top: -79px;
  left: 3px;
}

.relative {
  position: relative;
}

.crcl-d-2 .crcl-1-2 {
  padding: 36px;
  margin-top: -9px;
}

.crcl-d-2 .crcl-1-3 {
  padding: 30px;
  margin-top: 5px;
}

.crcl-d-2-0 .img-play-1 {
  position: absolute;
  bottom: 0;
  left: -15px;
  width: 69%;
  margin-bottom: -13px;
}

.crcl-d-2-0 .img-play-1-2 {
  width: 70px;
  right: 100px;
  left: auto;
  top: -29px;
}

.crcl-d-2-0 .img-play-1-4 {
  width: 48%;
  left: auto;
  right: 20px;
}

.crcl-d .crcl-1 {
  padding: 19px;
  margin-top: 7px;
}

.crcl-d .crcl-2 {
  padding: 27px;
  margin-top: -4px;
  margin-left: 2px;
}

.crcl-d .crcl-3 {
  padding: 27px;
  margin-top: 6px;
}

.prediction-sec {
  background-color: #fff;
  padding: 4.5rem 0;
}

.prediction-sec .btn-play {
  background: #06bd53;
  width: 100%;
  display: block;
  text-align: center;
  padding: 12px 0rem 12px 0;
  border-radius: 10px;
  color: #000;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 16px;
  transition: 0.6s ease;
  cursor: pointer;
  border: 1px solid transparent;
  text-decoration: none;
}

.prediction-sec h2 {
  font-family: Futura, sans-serif;
  font-weight: 300;
  color: #fff;
}

.prediction-sec h1 {
  font-family: "Premier 2019", sans-serif;
  color: #e81d63;
  font-weight: 500;
  font-size: 46px;
  text-transform: capitalize;
}

.prediction-sec h4 {
  font-family: "Rubik", sans-serif;
  font-weight: 300;
  color: #000;
}

.footer-sec {
  background-color: #121212;
}

.footer-sec h4 {
  color: #06bd53;
  font-weight: 300;
  font-family: "Rubik", sans-serif !important;
}

.footer-sec p {
  color: #929292;
  font-weight: 300;
  font-size: 20px;
  font-family: "Rubik", sans-serif !important;
}

.border-right {
  border-right: 1px solid #ffffff;
}

.prediction-card .header-row {
  background-color: #1a1a1a;
  border-top-right-radius: 11px;
  border-top-left-radius: 13px;
  margin-left: -18px;
  margin-right: -18px;
}

.prediction-card .pre-head {
  color: #e81d63;
  font-family: "Rubik", sans-serif !important;
}

.prediction-select {
  background: transparent;
  color: #939393 !important;
  text-align: center;
  border: none;
  position: absolute;
  width: 44px;
  /* top: 11px; */
  -webkit-appearance: none;
}

.prediction-card p {
  color: #929292;
  font-family: "Rubik", sans-serif !important;
}

.prediction-card .btn-outline-success {
  padding: 5px 92px;
  border-radius: 20px;
  color: #06bd53;
  border-color: #06bd53;
}

.prediction-card .footer-row {
  background-color: #1a1a1a;
  border-bottom-right-radius: 11px;
  border-bottom-left-radius: 13px;
  margin-left: -18px;
  margin-right: -18px;
}

.prediction-card .col-lg-4 {
  border-right: 1px solid #000000;
}

.prediction-card .footer-row h4 {
  color: #e1e1e1;
  font-size: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: "Rubik", sans-serif !important;
}

.prediction-card .footer-row p {
  font-size: 12px;
  font-style: italic;
  color: #e1e1e1;
  font-family: "Rubik", sans-serif !important;
}

.prediction-card input {
  background: transparent;
  color: #e1e1e1 !important;
  font-family: "Rubik", sans-serif !important;
}

.prediction-card .footer-row img {
  width: 41px !important;
}

.prediction-sec .slick-prev,
.prediction-sec .slick-next {
  width: 24px;
  height: 24px;
  background: #06bd53;
  border-radius: 100px;
}

.prediction-sec .slick-prev:hover,
.prediction-sec .slick-next:hover,
.prediction-sec .slick-prev:focus,
.prediction-sec .slick-next:focus {
  background: #06bd53;
}

.prediction-sec .slick-prev {
  transform: rotate(180deg);
}

.prediction-sec .slick-next:before {
  background-image: url("../images/arrow.svg");
  background-size: 6px;
  background-position: center;
  position: relative;
  left: 1px;
  top: 0;
}

.prediction-sec .slick-prev:before {
  background-image: url("../images/arrow.svg");
  background-size: 6px;
  background-position: center;
  position: relative;
  left: 1px;
  top: 0;
}

.prediction-sec .slick-prev {
  left: -50px;
}

.prediction-sec .slick-next {
  right: -50px;
}

a {
  text-decoration: none;
  cursor: pointer;
}

/* login-page */
.login_signup_wrapr {
  background-image: url('../images/loginSignupBg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}

.burger {
  padding-bottom: 80px;
  padding-top: 100px;
}

.site_form.login_form {
  background-color: #fff;
  padding: 40px 50px;
  border-radius: 15px;
  max-height: 507px;
}

.site_form.login_form h1 {
  font-size: 1.9em;
  position: relative;
  padding-bottom: 18px;
  margin-bottom: 50px;
  color: #000;
}

.site_form.login_form h1:after {
  content: "";
  position: absolute;
  width: 32px;
  height: 3px;
  background: #41d59e;
  left: 0;
  bottom: 0;
}

.custome_form_grp img {
  width: 24px;
  margin: 5px 16px 0 0;
}

.forgot-social {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custome_form_grp {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.custome_form_grp .form-controls {
  border: none;
  padding: 5px 10px;
  border-radius: 0;
  border-bottom: 1px solid #41d59e;
  font-size: 13px;
  font-weight: 600;
}

.site_form.login_form .form-check input {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  top: -3px;
  position: relative;
}

.site_form.login_form .form-control::placeholder {
  color: #28d69e;
  font-size: 13px;
  font-weight: 600;
}

.site_form.login_form .form-control:focus {
  box-shadow: none;
  outline: none;
}

.site_form.login_form .form-check {
  color: #333;
  font-size: 14px;
  margin: 10px 0;
}

.forgot {
  display: block;
  text-align: right;
  font-size: 14px;
  color: #000;
}

.login_form .btn {
  width: 100%;
  padding: 7px 10px;
  font-size: 14px;
  border-radius: 100px;
  color: #333;
  background: linear-gradient(1deg, #0f5d4b, #37bd9e, #28d69e);
  border: none;
}

.have-an-account {
  display: block;
  padding: 15px 0 0;
  font-size: 13px;
  font-weight: normal;
  color: #333;
  text-align: center;
}

.have-an-account a {
  color: #28d69e;
  font-weight: bold;
  font-size: 16px;
}

.forgot:hover {
  color: #28d6ab;
}

.custome_form_grp .field_group {
  width: calc(100% - 40px);
}

.login-space {
  padding: 75px 0;
}

.tearm-condition .form-check input {
  float: none;
}

.tearm-condition .form-check {
  margin: 0 !important;
}

.tearm-condition {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.tearm-condition a {
  font-size: 16px;
  padding-left: 7px;
  color: #28d69e;
}

.small-text {
  font-size: 12px;
}

.tearm-condition .form-check input {
  float: none;
}

.tearm-condition .form-check {
  margin: 0 !important;
}

.tearm-condition {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.tearm-condition a {
  font-size: 16px;
  padding-left: 7px;
  color: #28d69e;
}


.small-text {
  font-size: 12px;
}

.register_form .btn.btn-primary {
  margin: 25px 0 10px 0;
  width: 240px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}



.front_menu_sidebar {
  height: 100%;
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  background-color: #ad2b71;
  /* background-image: url('../images/menu-bg.png'); */
  width: 301px;
  padding: 60px 20px 36px 59px;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
  transform: translate(100%);
  justify-content: center;
  /* text-align: center; */
}

.front_menu_sidebar.active {
  transform: translate(0%);
}

.menu_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 24px 0;
}

.menu_head button {
  background: transparent;
  border: none;
}

.menu_head button img {
  max-width: 15px;
}

.menu_head h2 {
  margin: 0;
  color: #000;
}

.front_menu_link li a {
  padding: 8px 0px;
  text-decoration: none;
  font-size: 20px;
  color: #000000;
  display: block;
  transition: 0.3s;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  cursor: pointer;
}

.front_menu_link ul {
  padding: 0;
}


/* .main-wrapper-outer {
  padding: 25px 30px;
} */

.login_form .form-check-input:checked {
  background-color: #28d69e;
  border-color: #28d69e;
}

.login_form .form-check-input:checked:focus {
  box-shadow: none;
  outline: none;
}

/* .header-right #basic-nav-dropdown1 ~ .dropdown-menu.show {
  transform: translate(0% , 50px)!important;
}


.header-right #basic-nav-dropdown1 ~ .dropdown-menu{
  min-width: 400px;
  max-width: 400px;
} */

/* .header-right #basic-nav-dropdown1 ~ .dropdown-menu ul {
  height: 400px;
} */

.css-1s2u09g-control,
.css-1pahdxg-control {
  border: 0.7px solid #929292;
  border-radius: 14px !important;
  background: transparent !important;
  padding: 18px 30px;
  flex: 1 1;
  max-width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #fff;
}

.css-6j8wv5-Input::placeholder {
  color: #fff !important;
}

.play-btn {
  background: #06BD53;
  width: 100%;
  display: block;
  text-align: center;
  padding: 12px 0rem 12px 0;
  border-radius: 10px;
  color: #000;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  font-size: 16px;
  transition: 0.6s ease;
  cursor: pointer;
  border: 1px solid transparent;
}

/* 30/9/22 */

.contact-card .form-control {
  padding: 11px 17px;
}

.contact-us-sec p {
  font-size: 24px;
}

.about-para p {
  font-size: 15px;
  line-height: 31px;
  text-align: justify;
}

.what-p-say .rate-us-btn {
  padding: 12px 60px;
  border-radius: 12px;
  color: #000;
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 13px;
  background: #06BD53;
}


.what-p-say .card figure {
  width: 55px;
  height: 55px;
  margin: 0;
  border-radius: 100%;
  overflow: hidden;
}

.what-p-say .card figure img {
  width: 100%;
  height: 100%;
}

.what-p-say .card figcaption {
  width: calc(100% - 54px);
  padding-left: 20px;
}

.what-p-say .card {
  padding: 18px;
  background: white;
  color: #000;
  margin: 0 auto;
  margin: 0px 18px;
  /* text-align: center; */
}

.what-p-say .card figcaption i {
  color: #06BD53;
  margin-right: 5px;
  font-size: 13px;
  /* cursor: pointer; */
}

.front_menu_link li a:hover {
  color: #fff;
}

/* 30/9/22 */
.history-result-form .form-control {
  width: 70px !important;
  padding: 9px 10px !important;
  text-align: center;
  font-size: 20px;
  margin: 0px 10px;
  margin-bottom: 0px !important;
}

.profile-form .form-control option {
  color: black;
}

.upload-image-figure figure {

  position: relative;

}

.upload-image-figure figure img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  overflow: hidden;
  object-fit: cover;
  border: 1px solid #fff;
}


.upload-image-figure figure i {
  position: absolute;
  background: red;
  display: block;
  padding: 6px;
  border-radius: 100%;
  top: -7px;
  right: 0;
  font-size: 9px;
}

.upload-image-figure figure input {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
}

.notifications_list {
  /* width: 250px !important; */
  width: 100% !important;
}

.notifications_list h3 {
  font-size: 14px;
  margin: 0;
  font-weight: 300;
  display: flex;
  flex-direction: column;

}

.notifications_list h3 span {
  display: inline-block;
  background: #06bd53;
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 10px;
  min-width: 32px;
  margin-top: 5px;
  margin-left: 0;
  margin-right: 130px;
}

.notifications_list ul li {
  border-bottom: 1px solid #e3d7d72b;
  padding-bottom: 13px;
}

.notifications_list ul li .btn {
  width: 30px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  font-size: 12px;
}

.notifications_list ul {
  padding-top: 5px;
}

.notifications_list p {
  text-align: right;
  font-size: 13px;
  border-bottom: 1px solid #ebe9e921;
  padding: 10px 10px;
  cursor: pointer;
}

.notifications_list p:hover {
  color: #06bd53;
}

.notifications_list ul {
  overflow-y: auto;
  height: 311px;
  padding-right: 11px;
}

/* chat */

.chat .conversation .messages .msg-text {
  width: -moz-fit-content;
  width: fit-content;
  font-size: 0.85em;
  animation: appear-msg ease 0.25s forwards;
  margin-bottom: 12px;
  max-width: 75%;
}

.chat .conversation .messages .msg-text .text {
  display: block;
  padding: 0.75em;
  border-radius: 0.75em 0.75em 0.75em 0;
  background: #00000054;
  box-shadow: 0 2px 0 #00000020;
}

.chat .conversation .messages .msg-text.owner {
  margin-left: auto;
}

.chat .conversation .messages .msg-text.owner .text {
  color: #fff;
  background: #00000054;
  border-radius: 0.75em 0.75em 0 0.75em;
}

.chat .conversation .messages {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25em;
  overflow: auto;
  padding: 15px;
}

.common-tab.chat-tab .conversation {
  height: 324px;
}

.chat-search-tab {
  padding: 12px 19px;
}


.chat-search-tab .form-control {
  border: 1px solid #ffffffa1 !important;
}

.chat-tab .chat-username {
  font-size: 15px;
  padding-left: 17px;
  padding: 10px 15px;
  display: block;
  background: #02441E;
}

.chat-username span {
  cursor: pointer;
  margin-right: 13px;
  font-size: 14px;
  display: inline-block;
}

/* chat */


.stats-common-head figure img {
  width: 100%;
  height: 100%;
}

.stats-common-head figure {
  overflow: hidden;
}

.scrolling-slider .slick-track {
  transform: translate3d(0px, 0px, 0px) !important;
}

.global-team-outer .predication_tag img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.competion-inner-listing li .competion-user-flex figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.my-profile_friends {
  max-height: 484px;
  overflow-y: auto;
  padding: 12px;
}

.match-leadge-scoller,
.global-team-outer,
.my-profile_friends,
.predict-scoller,
.standing-sec-scoller,
.feed-sec-scoller,
.feed-subComment-sec-scoller {
  max-height: 744px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.standing-sec-scoller .team_cols_list {
  margin-right: 7px;
  cursor: pointer;
}

.cross-wapper-icon {
  position: absolute;
  line-height: 52px;
  margin-left: 13px;
  cursor: pointer;
}

.sidebar-menu li i {
  text-decoration: none;
  color: #828282;
  font-size: 14px;
  line-height: 20px;
  padding: 21px 20px;
  display: block;
  border-radius: 12px;
  transition: 0.3s;
  font-weight: 500;
}

.notifications_list li {
  padding: 0px 0px 13px 0px !important;
}

small.form-text.text-danger.help-block {
  min-height: 20px;
  display: block;
}

.feed-card .accordion-button::after {
  background-image: url(http://picknpredict.tk/static/media/greearrow.674735ff114f4dd92736.svg);
  background-size: 12px;
  transform: rotate(0deg);
  margin-top: 4px;
}

.accordion-button:not(.collapsed)::after {
  transform: rotate(180deg);
  margin-top: -7px;
}



.social-buttons {
  display: flex;
  align-items: center;
  text-align: center;
}

p.instagram {
  padding: 0px !important;
}

.instagram i img {
  width: 25px;
}

.instagram button {
  margin: 0 !important;
}


p.instagram button {
  padding: 0px !important;
  background: none !important;
  box-shadow: none !important;
  margin: 0px 10px !important;
}

.main-wrapper-outer {
  padding: 20px;
}

.feed-sec-scoller {
  max-height: 350px !important;
  overflow-y: auto;
  margin-bottom: 10px;
}

.feed-subComment-sec-scoller {
  max-height: 300px !important;
  overflow-y: auto;
  margin-bottom: 10px;
}

/* additional-css */
.history-result-form {
  margin: 0px 0 105px 0px;
}


.friend-predict-wrapper.d-flex.justfify-content-between {
  justify-content: center;
  align-items: center;
}

.friend-goal-list {
  flex: 0 0 23%;
  text-align: center;
}

.friend-goal-list ul li {
  margin-bottom: 13px;
  color: #828282;
  font-size: 14px;
  font-weight: ita;
  font-style: italic;
}

.friend-goal-list ul li img {
  margin-right: 12px;
}

/* .finished-outer {
  flex: 0 0 54%;
  max-width: 54%;
} */


.predication_tag img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.common-tab.chat-tab {
  z-index: 999 !important;
}

/* additional-css */

.header-predication {
  width: 59px;
  height: 59px;
  flex: 0 0 59px;
}

/* .header-right ul{
  display: flex;
} */




.competition-prize-second>img {
  position: absolute;
  left: 0;
}

.competition-prize-second {
  padding-left: 24px;
  position: relative;
}

.competition-prize-second p {
  margin-bottom: 0;
}

.multi_selected_users p {
  margin: 0 10px 5px 0;
  font-size: 12px;
  background: #1e2637;
  padding: 3px 10px;
  border-radius: 50px;
}

.multi_selected_users p img {
  max-width: 10px;
  margin-left: 7px;
}


.multi_selected_users {
  flex-wrap: wrap;
}

.tearm-condition_modal_main .modal-dialog {
  max-width: 950px;
}

.tearm-condition_modal_main .modal-dialog p {
  font-size: 14px;
  line-height: 1.6;
}

.tearm-condition_modal_main .modal-dialog strong {
  font-size: 16px;
  margin: 10px 0 15px 0;
}

.about-para-model {
  max-height: 500px;
  overflow-y: auto;
}

span.cross-icon-tab {
  position: absolute;
  right: 4px;
  top: 4px;
  background: #fff;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  cursor: pointer;
}

span.cross-icon-tab img {
  width: 11px;
}


.brand-for-mobile .brand-data {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.brand_cols.brand-for-mobile {
  display: flex;
  justify-content: space-between;
}

.myPrediction-dashboard .main_content_part {
  padding: 9px 0 0px 0;
}

.opensidebar .front_menu_sidebar {
  transform: translate(0%);
}

.opensidebar::before {
  position: fixed;
  content: "";
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .4;
  top: 0;
  bottom: 0;
  height: 100vh;
}

.slide-section a {
  font-size: 31px;
  color: #fff;
  display: block;
}

.slide-content {
  position: absolute;
  top: 66%;
  width: 747px;
  margin: 0 auto;
  display: block;
  left: 50%;
  transform: translateX(-50%);
  line-height: 45px;
  padding: 0px 27px;
}

.edit-slot-sec {
  padding: 13px 0px;
}


.table-stripeed th {
  background: #fff !important;
  /* color: #fff; */
}

.table-stripeed tbody td {
  color: #fff;
  font-size: 14px;
  font-weight: 100;
  padding: 15px 10px;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  color: #fff;
}

.mobile-leadge .history-result-form {
  transform: translate(0);
}



.common-tab.chat-tab .conversation {
  height: 300px;
}

.fullheight-tabs {
  height: calc(100vh - 220px);
}

.large_tabs_full_page .nav.nav-tabs .nav-item .nav-link,
.large_tabs_full_page .nav.nav-tabs .nav-item .nav-link.active {
  font-size: 20px;
}

.post-image a {
  display: inline-block;
  width: 100%;
  height: 300px;
  max-height: initial;
}

.post-image a img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position:top;
}



.tabs_version_second {
  background: #141A26;
  border: 1px solid #323B4E;
  border-radius: 18px;
  padding: 15px 0;
  position: relative;
}

.compittion_form form {
  border: 1px solid #323b4e;
  padding: 5px;
  border-radius: 5px;
  max-width: 340px;
  position: absolute;
  right: 20px;
  background: #222731;
}

.compittion_form form input {
  width: calc(100% - 80px);
  margin-right: 10px;
  height: 40px;
  padding: 5px 15px;
  border-radius: 5px;
  border: none;
  background: transparent;
  color: #fff;
}

.compittion_form form input:focus {
  outline: none;
}

.compittion_form form .btn {
  width: 70px;
  background: #06bd53;
  color: #fff;
  padding: 8px 15px;
  height: 40px;
}

.compittion_form form .btn:hover {
  background-color: #06bd53;
}


.tabs_version_second .nav-tabs {
  border-radius: 0;
  border: none;
  margin-top: 15px;
  justify-content: flex-start;
  margin: 0 !important;
  padding: 0 10px;
}

.tabs_version_second .nav-tabs .nav-link {
  font-size: 18px;
  margin: 0;
  padding: 15px 20px;
}



.tabs_version_second .tab-content .nav-link.active {
  border-color: transparent;
}

.tabs_version_second>.tab-content {
  padding: 0 24px;
}

.tabs_version_second>.tab-content .nav-tabs {
  background: transparent;
}

.tabs_version_second .competition-listing {
  padding-top: 20px;
}

.competion-inner-listing ul li {
  flex: 0 0 50%;
  max-width: calc(50% - 20px);
  margin: 10px;
  border: 2px solid transparent;
}

.competion-inner-listing ul li.online-user {
  border: 2px solid #06bd53;
}

.competion-inner-listing.half_none ul li {
  flex: 0 0 100%;
  max-width: calc(100% - 20px);
  margin: 10px;
}



.competion-inner-listing ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
}

.find-freands-tab {
  float: left;
  width: 100%;
}


.friend-col-wrapper {
  background: #141a26;
  border-radius: 24px;
  padding: 74px 20px 20px 20px;
  position: relative;
  height: calc(100vh - 136px);
  overflow-y: auto;
}

.friend-col-wrapper .form-group.common-input {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
}

.friend-col-wrapper .form-group.common-input .search {
  top: 18px;
  margin: 5px 0;
}

.friend-col-wrapper .form-group.common-input input {
  padding-left: 38px;
}

.edit-post {
  flex: 1;
  text-align: right;
  padding-left: 10px;
}

.friend-profile {
  max-height: 50px;
  border-radius: 100%;
}


.competion-inner-listing.full-listing ul li {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 20px;
  margin: 5px 10px;
}

.competion-inner-listing.full-listing ul li figcaption h5 {
  font-size: 18px;
  margin: 0;
}

.competion-inner-listing.full-listing ul li figcaption span {
  font-size: 13px;
}

.competion-inner-listing li .competion-user figure {
  flex: 0 0 50px;
  height: 50px;
  max-width: 50px;
}

.competion-inner-listing li .competion-user figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.competion-inner-listing li .competion-user figure {
  flex: 0 0 50px;
  height: 50px;
  max-width: 50px;
  position: relative;
}

/* .competion-inner-listing .online-user figure:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #06bd53;
  position: absolute;
  border-radius: 50px;
  right: 0;
  top: 0;
} */


.competion-inner-listing li .competion-user figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.competion-inner-listing.full-listing ul li .competion-user>.d-flex,
.competion-inner-listing.full-listing ul li .competion-user figcaption,
.competion-inner-listing.full-listing ul li .competion-user {
  flex: 1;
}

.css-u4tvz2-MuiFormLabel-root {
  color: white !important;
}

 
.action_right img {
  max-height: 16px;
  margin: 0 5px;
}

.prediction-delete {
  position: absolute;
  right: 15px;
}

.table-hover>tbody>tr:hover>*{color:#fff;}
.team_main_cls {
  display: flex;
  align-items: center;
}

.team_main_cls .table_icon {
  flex: 0 0 29px;
}

.team_main_cls .predication_value {
  padding-left: 10px;
  flex: 1;
}

.fixed_left {
  position: fixed;
  bottom: 80px;
}

